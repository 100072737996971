import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType} from "@data/enums/data.enums";
import {LongDataColumnComponent} from "@pages/segments/grid/columns/long-data-column/long-data-column.component";

export let pageContext: {
  [key: string]: boolean | string | undefined
  fn_f: string | undefined;
  l_f: string | undefined;
  sfu_f: string | undefined;
  dri_f: string | undefined;
} = {
  fn_f: undefined,
  l_f: undefined,
  sfu_f: undefined,
  dri_f: undefined
}
export let tableColumns: (GridColumn | GridColumnGroupDef)[] = [
  {
    field: 'fileName',
    headerName: 'File Name',
    hide: false,
    filterId: 'fn_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'locale',
    headerName: 'Language',
    hide: false,
    filterId: 'l_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {
      defaultToNothingSelected: true,
      buttons: ['reset']
    },
  },
  {
    field: 'sharepointFileUrl',
    headerName: 'SharePoint File URL',
    hide: false,
    filterId: 'sfu_f',
    filterParams: {
      buttons: ['reset']
    },
    cellRenderer: LongDataColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        text: params.data.sharepointFileUrl,
        type: 'link',
      };
    }
  },
  {
    field: 'fileDescription',
    headerName: 'File Description',
    hide: false,
    filterId: 'fd_f',
    filterParams: {
      buttons: ['reset']
    },
  },
  // Adding this here to eliminate the error caused by inheriting from table base component.
  {
    field: 'id',
    headerName: 'Request ID',
    hide: true,
    filterId: 'dri_f',
    filter: false,
    suppressColumnsToolPanel: true
  }
]
