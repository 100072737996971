import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {CheckboxItem, CheckListSelectionEvent} from "../ag-checkbox-list.component.ds";
import {v4 as uuidv4} from 'uuid';

@Injectable()
export class AgCheckboxListService {
  dataChange = new BehaviorSubject<CheckboxItem[]>([]);
  setSelection = new BehaviorSubject<CheckListSelectionEvent>({itemsToSelect: [], triggerSelectChangeEvent: false});

  constructor() {
  }

  /**
   * Gets the current items in the data source
   */
  get data(): CheckboxItem[] {
    return this.dataChange.value;
  }

  /**
   * Creates the list based on the list data provided
   * @param listData - The data source
   * @param labelPropertyKey - The key of the object we will use as the display propery
   */
  createAgCheckboxList(listData: any[], labelPropertyKey: string) {
    const data = this.buildCheckboxList(listData, labelPropertyKey);
    this.dataChange.next(data);
  }

  /**
   * This method allows us to pre-select items programmatically.
   * @param itemsToSelect - The list of items we want to select
   * @param propertyKeyName - The property name of the object that we want to match for equality
   * @param triggerSelectionChangeEvent - A flag to determine if the component should trigger the selection changed event after it's processed the items
   */
  setSelectedItems(itemsToSelect: any[], propertyKeyName: string, triggerSelectionChangeEvent: boolean) {
    // Find the checkbox items based on the propertyKeyName
    const checkBoxItemsToSelect = this.data.filter(item => itemsToSelect.some(itemToSelect => {
      const result = itemToSelect[propertyKeyName] === item.tag[propertyKeyName];
      return result;
    }));
    this.setSelection.next({itemsToSelect: checkBoxItemsToSelect, triggerSelectChangeEvent: triggerSelectionChangeEvent});
  }

  private buildCheckboxList(listData: any[], labelPropertyKey: string): CheckboxItem[] {
    const checkboxItem: CheckboxItem[] = [];
    listData.forEach(item => {
      const labelValue = this.extractPropertyByPath(item, labelPropertyKey);
      const node: CheckboxItem = {
        tag: item,
        id: uuidv4(),
        label: labelValue
      }
      checkboxItem.push(node);
    });

    return checkboxItem;
  }

  /**
   * Extract a nested property value from an object using a dot-separated key path.
   * @param obj - The object to extract the property from.
   * @param keyPath - The dot-separated path to the property.
   * @private
   */
  private extractPropertyByPath(obj: any, keyPath: string): any {
    return keyPath.split('.').reduce((acc, key) => acc && acc[key], obj);
  }
}
