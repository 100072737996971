import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {UniqueLinkInvitee, ViewMailoutFieldsets, ViewMailoutTemplates} from "@data/interefaces/panel.interfaces";
import {UniqueLinkInviteesService} from "@data/panel/unique.link.invitees.service";
import {PanelService} from "@data/panel/panel.service";
import {displayTableColumns, UniqueLinkInviteeWithProcessStatus} from "@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component.ds";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {LookupService} from "@data/lookup/lookup.service";
import {
  ViewSurveyBuCategories,
  ViewSurveyChannels,
  ViewSurveyIndustryFlags,
  ViewSurveyLocales,
  ViewSurveyLocalRegions,
  ViewSurveyRespondentCompanies,
  ViewSurveyRespondentDivisions,
  ViewSurveyRoles,
  ViewSurveySeniorities,
  ViewSurveySubjectRollups,
  ViewSurveySubjectSingles
} from "@data/interefaces/survey.spec.interfaces";
import {YearsInRole} from "@data/interefaces/lookup.interfaces";
import {maxCompaniesToRateValidator} from "@pages/panel/unique-link-invitees/unique-link-invitees.component.validator";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-unique-link-invitees-bulk',
  templateUrl: './unique-link-invitees-bulk.component.html',
  styleUrls: ['./unique-link-invitees-bulk.component.scss']
})
export class UniqueLinkInviteesBulkComponent extends BulkBaseComponent {
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined = [];
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined = [];
  invitationCompanyCodeList: ViewSurveyRespondentCompanies[] | undefined = [];
  invitationDivisionCodesList: ViewSurveyRespondentDivisions[] | undefined = [];
  buCategoriesList: ViewSurveyBuCategories[] | undefined = [];
  localRegionsList: ViewSurveyLocalRegions[] | undefined = [];
  senioritiesList: ViewSurveySeniorities[] | undefined = [];
  rolesList: ViewSurveyRoles[] | undefined = [];
  channelsList: ViewSurveyChannels[] | undefined = [];
  industryFlagsList: ViewSurveyIndustryFlags[] | undefined = [];
  subjectRollupsList: ViewSurveySubjectRollups[] | undefined = [];
  subjectSinglesList: ViewSurveySubjectSingles[] | undefined = [];
  surveyLanguageList: ViewSurveyLocales[] | undefined = [];
  yearsInRoleList: YearsInRole[] | undefined = [];
  @Input() override selectedRows: UniqueLinkInvitee[] = [];
  protected override formGroup: FormGroup<{ [K in keyof UniqueLinkInvitee]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = displayTableColumns;

  constructor(private _uniqueLinkInviteesService: UniqueLinkInviteesService, private _panelService: PanelService, private _surveySpecService: SurveySpecService,
              private _lookupService: LookupService) {
    super();
  }

  override ngOnInit() {
    this.displayProgressBar(true);
    super.ngOnInit();
    this.displayProgressBar(false);
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<UniqueLinkInviteeWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof UniqueLinkInvitee)) {
    return this.getControl<UniqueLinkInvitee>(controlName);
  }

  protected override initFormGroup() {
    let isBulkMailoutMode = this.featureComponentMode == FeatureComponentMode.BULK_MAILOUT;
    this.formGroup = new FormGroup<{ [K in keyof UniqueLinkInvitee]?: FormControl<any> }>(
      {
        invalid: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        invitationCompanyCode: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        invitationDivisionCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        preFillContactInformation: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        surveyLanguage: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        nominatedByCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        categoryResponsibility: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        nominationNotes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        preFillSurveySelections: new FormControl(
          {
            value: null,
            disabled: isBulkMailoutMode
          }, []),
        lockSurveySelections: new FormControl(
          {
            value: null,
            disabled: isBulkMailoutMode
          }, []),
        showDivisionsCollapsed: new FormControl(
          {
            value: null,
            disabled: isBulkMailoutMode
          }, []),
        startInReviewMode: new FormControl(
          {
            value: null,
            disabled: isBulkMailoutMode
          }, []),
        localRegionCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        seniorityCode: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        roleCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        allBuCategories: new FormControl(
          {
            value: null,
            disabled: isBulkMailoutMode
          }, []),
        buCategoryCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        channelCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        brPlCode: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        subjectRollupCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        subjectSingleCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        maxTotalCompaniesToRate: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, Validators.pattern("^[0-9]*$")),
        maxP1CompaniesToRate: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, Validators.pattern("^[0-9]*$")),
        maxP2CompaniesToRate: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, Validators.pattern("^[0-9]*$")),
        maxP3CompaniesToRate: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, Validators.pattern("^[0-9]*$")),
        mailoutTemplateCode: new FormControl({
          value: null,
          disabled: this.featureComponentMode != FeatureComponentMode.BULK_MAILOUT
        }, Validators.required),
        mailoutFieldSetCode: new FormControl({
          value: null,
          disabled: this.featureComponentMode != FeatureComponentMode.BULK_MAILOUT
        }, Validators.required),
        mailoutDatetime: new FormControl({
          value: null,
          disabled: this.featureComponentMode != FeatureComponentMode.BULK_MAILOUT
        }),
      });
    if (this.featureComponentMode == FeatureComponentMode.BULK_EDIT) {
      this.formGroup.setValidators(maxCompaniesToRateValidator(this.formGroup.controls.maxP1CompaniesToRate, this.formGroup.controls.maxP2CompaniesToRate,
        this.formGroup.controls.maxP3CompaniesToRate, this.formGroup.controls.maxTotalCompaniesToRate));
    }
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._uniqueLinkInviteesService.getLatestUniqueLinkInviteesBatchProcess();
  }

  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_MAILOUT:
        this.title = "Unique Link Invitation Bulk Mailout";
        this.subtitle = "Unique Link Invitation Bulk Mailout Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkMailoutDropdowns();
        break;
      case FeatureComponentMode.BULK_EDIT:
        this.title = "Unique Link Invitation Bulk Edit";
        this.subtitle = "Unique Link Invitation Bulk Edit Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkUpdateDropdowns();
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Unique Link Invitation Bulk Upload";
        this.subtitle = "Unique Link Invitation Bulk Upload Configuration";
        this.submitButtonText = "Start Upload";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  protected override startBatchProcess(selectedRows: UniqueLinkInvitee[]): Promise<any> {
    return this._uniqueLinkInviteesService.startUniqueLinkInviteesBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]) {
    let returnList: T[] = [];
    rowData.map(item => {
      const element = item as UniqueLinkInviteeWithProcessStatus;
      if (!element) {
        throw new Error('Element is not the proper type.');
      }
      if ((element.responseCode && !element.surveyInviteeId) || (!element.responseCode && element.surveyInviteeId)) {
        element.processingNote = 'To add, response code and survey code both should be null, for update, both should be provided';
        returnList.push(element as T);
      }
    });

    return returnList;
  }

  private loadBulkMailoutDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  private loadBulkUpdateDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._lookupService.getYearsInRole().then(result => {
      this.yearsInRoleList = result;
    });
    this._surveySpecService.getSurveyRespondentCompanies(surveyCode).then(result => {
      this.invitationCompanyCodeList = result;
    });
    this._surveySpecService.getSurveyRespondentDivisions(surveyCode).then(result => {
      this.invitationDivisionCodesList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.surveyLanguageList = result;
    });
    this._surveySpecService.getSurveyBuCategories(surveyCode).then(result => {
      this.buCategoriesList = result;
    });
    this._surveySpecService.getSurveyLocalRegions(surveyCode).then(result => {
      this.localRegionsList = result;
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
    this._surveySpecService.getSurveyRoles(surveyCode).then(result => {
      this.rolesList = result;
    });
    this._surveySpecService.getSurveyChannels(surveyCode).then(result => {
      this.channelsList = result;
    });
    this._surveySpecService.getSurveyIndustryFlags(surveyCode).then(result => {
      this.industryFlagsList = result;
    });
    this._surveySpecService.getSurveySubjectRollups(surveyCode).then(result => {
      this.subjectRollupsList = result;
    });
    this._surveySpecService.getSurveySubjectSingles(surveyCode).then(result => {
      this.subjectSinglesList = result.filter(s => s != null);
    });
    this._surveySpecService.getSurveySeniorities(surveyCode).then(result => {
      this.senioritiesList = result;
    });
  }
}
