import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-ag-not-found',
  templateUrl: './ag-not-found.component.html',
  styleUrls: ['./ag-not-found.component.scss']
})
export class AgNotFoundComponent {

  constructor(private _router: Router) {
  }

  onHomeClicked() {
    this._router.navigate(['/home', 'welcome']);
  }
}
