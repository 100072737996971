import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FollowChangePathService} from "@data/system/database/follow.change.path.service";
import {Util} from "@data/util/util";
import {pageContext, tableColumns} from "@pages/system/database/follow-change-path/follow-change-path.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {AgGridService} from "@shared/services/ag-grid.service";
import {FollowChangePath} from "@data/interefaces/itf_system.interfaces";
import {FirstDataRenderedEvent} from "ag-grid-community";

@Component({
  selector: 'app-follow-change-path',
  templateUrl: './follow-change-path.component.html',
  styleUrls: ['./follow-change-path.component.scss'],
  providers: [AgGridService]
})

export class FollowChangePathComponent extends TableBaseComponent implements AfterViewInit {

  rowData: FollowChangePath[] = [];
  auxLinks: Map<string, string>;
  showSkeleton: boolean = true;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;

  constructor(private _activatedRoute: ActivatedRoute, private _followChangePathService: FollowChangePathService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.updatePageContext();
  }

  ngAfterViewInit() {
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  async loadPageData() {
    this.refreshComplete = false;
    this.displayProgressBar(true);
      await this._followChangePathService.getFollowChangePath().then(result => {
        this.rowData = result;
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }
}
