import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {Feature} from "@core/interfaces/data.interfaces";


@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private cachedFeatures?: Feature[];

  constructor(private _apiService: ApiService) {
  }

  public async getFeatureFlags() {
    if (this.cachedFeatures) {
      return this.cachedFeatures;
    }
    try {
      this.cachedFeatures = await this._apiService.makeGetServiceCall<Feature[]>(`/v2/api/feature/feature-flags`);
    } catch (error) {
      console.error("Failed to fetch feature flags: ", error);
      throw error;
    }
    return this.cachedFeatures;
  }

  public async isFeatureEnabled(featureType: string, featureFlag: string): Promise<boolean> {
    const features = await this.getFeatureFlags();
    let featureEnabled = true;
    const feature = features.find(f => f.featureType === featureType);
    if (feature) {
      if (feature.featureFlags) {
        const featureFlagItem = feature.featureFlags.find(ff => ff.featureFlag === featureFlag);
        if (featureFlagItem) {
          featureEnabled = featureFlagItem.enabled;
        }
      }
    }
    return featureEnabled
  }
}
