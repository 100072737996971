import {Component, Input} from '@angular/core';
import {SurveyTracker, ViewMailoutFieldsets, ViewMailoutTemplates} from "@data/interefaces/panel.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ViewSurveyLocales,} from "@data/interefaces/survey.spec.interfaces";
import {Util} from "@data/util/util";
import {SurveySpecService} from "@data/survey-spec/survey.spec.service";
import {FileRequestStatus} from "@data/enums/data.enums";
import {surveyTrackerDisplayTableColumns, SurveyTrackerWithProcessStatus} from "@pages/panel/survey-tracker/survey-tracker-bulk/survey-tracker-bulk.component.ds";
import {SurveyTrackerService} from "@data/panel/survey.tracker.service";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {PanelService} from "@data/panel/panel.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-survey-tracker-bulk',
  templateUrl: './survey-tracker-bulk.component.html',
  styleUrls: ['./survey-tracker-bulk.component.scss']
})
export class SurveyTrackerBulkComponent extends BulkBaseComponent {
  mailingLocaleList: ViewSurveyLocales[] | undefined = [];
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined = [];
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined = [];
  fileRequestStatusList: FileRequestStatus[] | undefined = [];
  @Input() override selectedRows: SurveyTracker[] = [];
  protected override formGroup: FormGroup<{ [K in keyof SurveyTracker]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = surveyTrackerDisplayTableColumns;

  constructor(private _surveyTrackerService: SurveyTrackerService, private _panelService: PanelService, private _surveySpecService: SurveySpecService) {
    super();
  }

  override ngOnInit() {
    this.displayProgressBar(true);
    super.ngOnInit();
    this.displayProgressBar(false);
  }

  getFormControl(controlName: (keyof SurveyTracker)) {
    return this.getControl<SurveyTracker>(controlName);
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<SurveyTrackerWithProcessStatus>(event);
  }

  protected override initFormGroup() {
    let isBulkRequestResponseFilesMode = this.featureComponentMode == FeatureComponentMode.BULK_REQUEST_FILES;
    let isBulkMailoutMode = this.featureComponentMode == FeatureComponentMode.BULK_MAILOUT;
    let isBulkEditMode = this.featureComponentMode == FeatureComponentMode.BULK_EDIT;
    this.formGroup = new FormGroup<{ [K in keyof SurveyTracker]?: FormControl<any> }>(
      {
        mailingLocale: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        nominatedCategories: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        nominationNotes: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        endorserName: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        invalidateRatings: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        invalidateVerbatim: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        pmNotes: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        notesToPM: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        mailoutTemplateCode: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }, Validators.required),
        mailoutFieldSetCode: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }, Validators.required),
        mailoutDatetime: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }),
        responseFilesStatus: new FormControl({
          value: isBulkRequestResponseFilesMode ? this.selectedRows[0].responseFilesStatus : null,
          disabled: !isBulkRequestResponseFilesMode
        }),
      });
  }

  protected override startBatchProcess(selectedRows: SurveyTracker[]): Promise<any> {
    return this._surveyTrackerService.startSurveyTrackersBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]): T[] {
    return [];
  }

  protected override getBatchProcessResultByType() {
    return this._surveyTrackerService.getLatestSurveyTrackersBatchProcess();
  }

  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_REQUEST_FILES:
        this.title = "Request Response Files in Bulk";
        this.subtitle = "Request Response Files in Bulk Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkRequestResponseFilesDropdowns();
        break;
      case FeatureComponentMode.BULK_MAILOUT:
        this.title = "Survey Tracker Bulk Mailout";
        this.subtitle = "Survey Tracker Bulk Mailout Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkMailoutDropdowns();
        break;
      case FeatureComponentMode.BULK_EDIT:
        this.title = "Survey Tracker Bulk Edit";
        this.subtitle = "Survey Tracker Bulk Edit Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkUpdateDropdowns();
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Survey Tracker Bulk Upload";
        this.subtitle = "Survey Tracker Bulk Upload Configuration";
        this.submitButtonText = "Upload and Process";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  private loadBulkRequestResponseFilesDropdowns() {
    this.fileRequestStatusList = [Util.getFileRequestStatusTransition(this.selectedRows[0].responseFilesStatus)];
  }

  private loadBulkMailoutDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  private loadBulkUpdateDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.mailingLocaleList = result;
    });
  }
}
