import {SelectionChangeType} from "@data/enums/data.enums";

/**
 * Checkbox Node
 */
export class CheckboxNode {
  children: CheckboxNode[] = [];
  item: string = '';
  id: string = '';
  parentId?: string;
  tag: any;
}

/** Flat ParentCheckboxNode with expandable and level information */
export class ParentCheckboxNode {
  item: string = '';
  level: number = 0;
  expandable: boolean = false;
  id: string = '';
  tag: any;
}

export interface TreeSelectionChanged {
  parentItem: any;
  childrenItems: any[];
  changeType: SelectionChangeType
}

export interface TreeSelectionEvent {
  itemsToSelect: CheckboxNode[],
  triggerSelectChangeEvent: boolean
}
