import {ActivatedRouteSnapshot, CanActivateFn, Router} from "@angular/router";
import {FeatureService} from "@core/feature/feature.service";
import {inject} from "@angular/core";

export function featureGuard(feature: string, featureFlag: string, mode?: string): CanActivateFn {
  return async (route: ActivatedRouteSnapshot) => {
    const featureService = inject(FeatureService);
    const router = inject(Router);
    let isFeatureEnabled = true;
    // Mode was passed in, lets compare it to the paramMap
    if (mode) {
      const modeSnapshot = route.paramMap.get('mode');
      if (modeSnapshot && modeSnapshot === mode) {
        isFeatureEnabled = await featureService.isFeatureEnabled(feature, featureFlag);
      }
    } else {
      isFeatureEnabled = await featureService.isFeatureEnabled(feature, featureFlag);
    }
    if (isFeatureEnabled) {
      return true;
    } else {
      router.navigate(['/404']);
      return false;
    }
  };
}
