<div class="full-height-container">
  <mat-card class="full-height-container">
    <mat-card-content class="full-height-container content-panel">
      <div class="flex-row">
        <app-component-header (refreshClickEvent)="this.refreshView()"
                              (downloadClickEvent)="this.handleDownloadClickEvent('follow-change-path')"
                              [hideColumnsButton]="true"
                              [startRefreshTimer]="this.refreshComplete"
                              [subtitleSegments]="getUrlSegments()"
                              redirectTo=''
                              [hideUploadButton]="true"
                              class="full-width"></app-component-header>
      </div>
      <div class="flex-row table-container">
        <ag-grid-angular (columnVisible)="onColumnVisible($event)"
                         (firstDataRendered)="onFirstDataRendered($event)"
                         (gridReady)="onGridReady($event)"
                         (viewportChanged)="onViewportChanged($event)"
                         [columnDefs]="tableColumns"
                         [gridOptions]="baseAgGridOptions"
                         [overlayNoRowsTemplate]="noRowsTemplate"
                         [rowData]="rowData"
                         [suppressPropertyNamesCheck]="true"
                         class="ag-theme-quartz full-width">
        </ag-grid-angular>
      </div>
    </mat-card-content>
  </mat-card>
</div>
