export enum FeatureComponentMode {
  DEFAULT = 'default',
  BULK_REQUEST_FILES = 'bulk-request-files',
  BULK_INCLUDE = 'bulk-include',
  BULK_MAILOUT = 'bulk-mailout',
  BULK_EDIT = 'bulk-edit',
  ADD_ROW = 'add',
  EDIT_ROW = 'edit',
  UPLOAD = 'upload',
  DETAIL = 'detail',
  ADD_LEGACY = 'add-legacy', // Support for outdated / temp add modules
  DATA = 'data', // switch data/chart mode
  CHART = 'chart'
}
