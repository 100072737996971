import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {Util} from "@data/util/util";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {MatSort} from "@angular/material/sort";
import {FieldConfiguration, ProductRespondentCompany, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ActivatedRoute} from "@angular/router";
import {RespondentCompanyService} from "@data/product-spec/respondent.company.service";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import {RespondentRollupService} from "@data/product-spec/respondent.rollup.service";
import {TableBaseComponent} from "@pages/table.base.component";
import {marketCodes, pageContext, periods, productCodes, tableColumns} from "@pages/product-spec/product-assignment/respondent-company/respondent-company.component.ds";
import {FilterChangedEvent, FirstDataRenderedEvent, SelectionChangedEvent} from "ag-grid-community";
import {YesNoValue} from "@data/enums/data.enums";
import {ProductSpecService} from "@data/product-spec/product.spec.service";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {AgGridService} from "@shared/services/ag-grid.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-respondent-company',
  templateUrl: './respondent-company.component.html',
  styleUrls: ['./respondent-company.component.scss'],
  providers: [AgGridService]
})
export class RespondentCompanyComponent extends TableBaseComponent implements AfterViewInit {

  public isFilterActive: boolean = false;
  auxLinks: Map<string, string>;
  @ViewChild("filterSidePanel") filterSidePanel: AgSidePanelComponent | null = null;
  @ViewChild("respondentCompanyEdit") respondentCompanyEdit: AgSidePanelComponent | null = null;
  @ViewChild("languageEdit") languageEdit: AgSidePanelComponent | null = null;

  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  dataSource: ProductRespondentCompany[] = [];
  refreshComplete: boolean = true;
  showSkeleton: boolean = true;
  showPanelProgress: boolean = false;
  selectedRespondentCompany: ProductRespondentCompany | undefined;


  dynamicPanelConfig: string | undefined;

  selectedFeatureMode!: FeatureComponentMode;
  showTaskComponent: boolean = false;

  isBulkOperationAllowed = false;
  selectedRows: ProductRespondentCompany[] = [];
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns

  constructor(private _activatedRoute: ActivatedRoute, private _productSpecService: ProductSpecService, private _respondentCompanyService: RespondentCompanyService,
              private _respondentRollupService: RespondentRollupService, private _referenceService: ReferenceService, private _lookupService: LookupService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.loadFilterData();
    this.updatePageContext();
  }

  ngAfterViewInit() {

  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
  }

  async refreshView() {
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  loadFilterData() {
    this._lookupService.getPeriods().then(result => {
      result.forEach(item => periods.push(item.period));
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => marketCodes.push(item.marketCode));
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this._productSpecService.getProductCodes().then(result => {
      result.forEach(item => productCodes.push(item.productCode));
    })
  }

  goBack() {
    this.showTaskComponent = false;
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
    this.updateToPageContextUrl();
  }

  async loadPageData() {
    this.displayProgressBar(true);
    const productPeriods = this.pageContext.p_f || '';
    const marketCodes = this.pageContext.mc_f || '';
    const productCodes = this.pageContext.pc_f || '';
    let thresholds: ResultsetThresholds | undefined;
    this.refreshComplete = false;
    await this._respondentCompanyService.getRespondentCompanyThresholds(productPeriods, marketCodes, productCodes).then(result => {
      thresholds = result;
      this.setRowTemplateMessage(thresholds);
    }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error));
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );

    if (thresholds && !thresholds.hasTooMany) {
      await this._respondentCompanyService.getRespondentCompanyList(productPeriods, marketCodes, productCodes).then(result => {
        this.dataSource = result;
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => {
          this.displayProgressBar(false);
          this.refreshComplete = true;
        }
      );
    } else {
      this.refreshComplete = true;
      this.dataSource = [];
      this.displayProgressBar(false);
    }
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    this.refreshGridHeaderOnSelectionChanged(event);
    this.selectedRows = this.getSelectedRows();
    this.isBulkOperationAllowed = this.selectedRows.length != 0 && this.selectedRows.every(item => item.marketCode === this.selectedRows[0].marketCode);
  }

  onBulkIncludeClicked() {
    this.selectedFeatureMode = FeatureComponentMode.BULK_INCLUDE;
    this.showTaskComponent = true;
  }

  includeAllSelectedClicked(status: boolean) {
  }

  onIncludeToggleChange(event: MatSlideToggleChange) {
    if (this.selectedRespondentCompany !== undefined) {
      this.selectedRespondentCompany.include = event.checked ? YesNoValue.YES_VALUE : ' ';
    }
  }

  onProductRespondentCompanyEditClick(productRespondentCompany: ProductRespondentCompany, isReadOnly: boolean) {
    this.selectedRespondentCompany = {...productRespondentCompany};
    this.respondentCompanyEdit?.toggle()
    this.showSkeleton = false;
  }

  onLanguageClick(productRespondentCompany: ProductRespondentCompany) {
    if (this.languageEdit) {
      this.showSkeleton = true;
      this._respondentRollupService.getRespondentRollupReportLabelList(productRespondentCompany.productPeriodId, productRespondentCompany.respondentCompanyCode)
        .then(result => {
          let configMap = result.map<FieldConfiguration>(element => {
            let filedConfiguration: FieldConfiguration = {
              fieldId: element.language,
              displayLabel: element.language,
              type: 'string',
              value: element.respondentCompanyRollupSurveyLabel,
              valueList: '',
              labelList: ''
            };
            return filedConfiguration;
          });
          this.dynamicPanelConfig = JSON.stringify(configMap);
          this.showSkeleton = false;
        });
      this.languageEdit.toggle();
    }
  }

  enableScheduleBulkAction() {
    // let selectedRecords = this.util.getSelectedRecords(this.dataSource, this.selectionModel);
    // if (selectedRecords.length == 0) {
    //   return false;
    // }
    // let enableSchedule = true;
    // selectedRecords.forEach(record => {
    //   enableSchedule = !record.scheduled && enableSchedule;
    // });
    //
    // return enableSchedule;
  }

  enableUnScheduleBulkAction() {
    // let selectedRecords = this.util.getSelectedRecords(this.dataSource, this.selectionModel);
    // if (selectedRecords.length == 0) {
    //   return false;
    // }
    // let enableUnSchedule = true;
    // selectedRecords.forEach(record => {
    //   enableUnSchedule = record.scheduled && enableUnSchedule;
    // });
    //
    // return enableUnSchedule;
  }

  onIncludeToggleChanged($event: MatSlideToggleChange) {

  }

  onSaveClick() {
    if (this.selectedRespondentCompany !== undefined) {
      this.showPanelProgress = true;
      this._respondentCompanyService.updateRespondentCompany(this.selectedRespondentCompany).then(result => {
        if (result.status) {
          this.displayNotificationMessage('success', `Product Period Updated.`);
        }
        this.displayProgressBar(false);
      }).catch(error => {
        this.displayNotificationMessage('error', JSON.stringify(error));
      }).finally(() => this.showPanelProgress = false);
    }
  }

  protected override getTableFooterActionButtonPanel() {
    return this.footerActionButtonPanel;
  }

  protected override onFirstDataRendered(event: FirstDataRenderedEvent<any>) {
    super.onFirstDataRendered(event);
    event.api.autoSizeAllColumns();
  }

  protected readonly YesNoValue = YesNoValue;
}
