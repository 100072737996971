import {AfterViewInit, Component, Input} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Util} from "@data/util/util";
import {
  pageContext,
  tableColumns
} from "@pages/verbatim-file-rows/verbatim-file-rows.component.ds";
import {TableBaseComponent} from "@pages/table.base.component";
import {VerbatimFileRowInfo} from "@data/interefaces/verbatim.file.interfaces";
import {VerbatimRowService} from "@data/verbatim-row/verbatim-row.service";
import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {VerbatimFileRowsComponentUtil} from "@pages/verbatim-file-rows/verbatim-file-rows.component.util";

import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-verbatim-file-detail-rows',
  templateUrl: './verbatim-file-detail-rows.component.html',
  styleUrls: ['./verbatim-file-detail-rows.component.scss']
})

export class VerbatimFileDetailRowsComponent extends TableBaseComponent implements AfterViewInit {

  @Input() verbatimFileId: number | undefined;

  rowData: VerbatimFileRowInfo[] = [];
  showSkeleton: boolean = true;
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;

  protected readonly util = Util;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns: (GridColumn | GridColumnGroupDef)[] = tableColumns;

  constructor(private _activatedRoute: ActivatedRoute,
              private _verbatimRowService: VerbatimRowService
  ) {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this.updatePageContext();
  }

  ngAfterViewInit() {
    this.tableColumns.forEach(column => {
      if ((column as GridColumnGroupDef).children) {
        this.initColumnGroup(column as GridColumnGroupDef);
      } else {
        this.initColumn(column as GridColumn);
      }
    });
  }

  initColumnGroup(columnGroup: GridColumnGroupDef) {
    columnGroup.children.forEach(column => {
      if ((column as GridColumnGroupDef).children) {
        this.initColumnGroup(column as GridColumnGroupDef);
      } else {
        this.initColumn(column as GridColumn);
      }
    })
  }

  initColumn(column: GridColumn) {
    switch (column.field) {
      case 'surveyCode':
      case 'verbatimFileName':
      case 'verbatimFileUploadDate':
        // These columns are the same for every row, and are no longer a Data Filter.
        // Therefore, hide them by default and turn off the filtering.
        column.hide = true;
        column.filter = null;
        column.filterParams = null;
        break;
      default:
        // leave as is
        break;
    }
  }


  async loadPageData() {
    VerbatimFileRowsComponentUtil.setColumns(this.tableColumns, FeatureComponentMode.DETAIL);
    this.refreshComplete = false;
    this.displayProgressBar(true);
    await this._verbatimRowService.getVerbatimFileRowsByFile(this.verbatimFileId!).then(result => {
      this.rowData = result;
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
        this.displayProgressBar(false);
        this.refreshComplete = true;
      }
    );
  }

  updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
  }
}
