import {DetailBaseComponent} from "@pages/detail.base.component";
import {AfterViewInit, Component} from "@angular/core";
import {DownloadRequest, DownloadRequestDetailField, DownloadRequestMetrics, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {Util} from "@data/util/util";
import {DownloadRequestUtil} from "@data/download-request/download.request.util";
import {DownloadRequestProcessStatus, NotificationType} from "@data/enums/data.enums";
import {FormControl, FormGroup} from "@angular/forms";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {LookupService} from "@data/lookup/lookup.service";
import {DownloadRequestType} from "@data/interefaces/lookup.interfaces";
import {PptDownloadsService} from "@data/ppt-downloads/ppt-downloads.service";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'download-request-detail',
  templateUrl: './download-request-detail.component.html',
  styleUrls: ['./download-request-detail.component.scss']
})
export class DownloadRequestDetailComponent extends DetailBaseComponent implements AfterViewInit {
  auxLinks: any;
  downloadRequestFields: DownloadRequestDetailField[] | undefined;
  override formGroup: FormGroup<{ [K in keyof DownloadRequest]?: FormControl<any> }> = new FormGroup<any>([]);
  override pageContext: {
    [key: string]: boolean | string | undefined;
    dri_f: string | undefined;
  } = {
    dri_f: undefined,
  };
  downloadRequestMetrics?: DownloadRequestMetrics;
  downloadType?: DownloadRequestType;
  maxDownloadRequestThreshold: number = 0;
  protected downloadRequest: DownloadRequest | undefined;
  protected viewDownloadRequest: ViewDownloadRequest | undefined;
  protected override readonly util = Util;
  protected readonly downloadRequestUtil = DownloadRequestUtil;
  protected readonly downloadStatus = DownloadRequestProcessStatus;
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected override loading: boolean = false;
  protected isFilterActive: boolean = true;
  protected downloadRequestParameters!: any;
  private maxExpediteDownloadRequestsCount: number = 0;
  private currentMarketExpediteCount: number = 0;
  private readonly MAX_EXPEDITE_THRESHOLD_CODE: string = 'max-expedite-count';
  private readonly DEFAULT_PRIORITY: number = 5;
  private readonly EXPEDITED_PRIORITY: number = 1;

  constructor(private _downloadRequestService: DownloadRequestService, private _pptDownloadsService: PptDownloadsService, private _lookupService: LookupService) {
    super();
  }

  ngAfterViewInit(): void {
  }

  override ngOnInit(): void {
    this.displayProgressBar(true);
    super.ngOnInit();
  }

  isDownloadRequestStatusSelected(downloadStatusEnum: DownloadRequestProcessStatus) {
    return this.viewDownloadRequest?.processStatus == downloadStatusEnum.toString();
  }

  canCancel() {
    return this.viewDownloadRequest?.processStatus === DownloadRequestProcessStatus.PENDING &&
      (this._store.isAdmin || this.viewDownloadRequest.requestedBy === this._store.email);
  }

  async onCancelClick() {
    if (this.canCancel()) {
      const dialogResult = await this.displayDialogMessage(NotificationType.INFO, "Are you sure you want to cancel the selected download requests?");
      if (dialogResult) {
        this.displayProgressBar(true);
        this._downloadRequestService.cancelDownloadRequests([this.viewDownloadRequest?.id]).then(result => {
          if (result.status) {
            this.displayNotificationMessage("success", "Requests successfully cancelled");
          } else {
            this.displayNotificationMessage("warning", "Not all requests were cancelled.  Check Alert Messages column for more details.");
          }
        }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
          .finally(async () => {
            await this.loadData();
            this.displayProgressBar(false);
          });
      }
    }
  }

  canRetry() {
    if (this.viewDownloadRequest) {
      let batchCode = this.viewDownloadRequest?.batchCode;
      // batch codes ending with four zeros only can not be retried.  They indicate legacy batches.
      return (this.viewDownloadRequest?.processStatus !== DownloadRequestProcessStatus.PENDING && this.viewDownloadRequest?.processStatus !== DownloadRequestProcessStatus.IN_PROGRESS)
        && Util.isValidDownloadRequestBatchCode(batchCode);
    }
    return false;
  }

  async onRetryClick() {
    if (this.canRetry()) {
      // capture the original strategy
      const currentReuseStrategy = this._router.routeReuseStrategy.shouldReuseRoute;
      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
      this._router.navigate(['/data-management', 'download', 'download-request-queue', 'task', 'add'], {
        state: {requestsToRetry: [this.viewDownloadRequest]}
      }).then(() => {
        // reset the strategy
        this._router.routeReuseStrategy.shouldReuseRoute = currentReuseStrategy;
      });
    }
  }

  disableExpediteRequestButton() {
    const isNotPending = this.viewDownloadRequest?.processStatus != DownloadRequestProcessStatus.PENDING;
    const isRequestedByOtherAndNotAdmin = this.viewDownloadRequest?.requestedBy !== this._store.email && !this._store.isAdmin;
    return isNotPending ||
      (this.viewDownloadRequest?.priority === this.DEFAULT_PRIORITY && this.currentMarketExpediteCount >= this.maxDownloadRequestThreshold && !this._store.isAdmin) ||
      (this.viewDownloadRequest?.priority === this.EXPEDITED_PRIORITY && isRequestedByOtherAndNotAdmin);
  }

  onExpediteClick() {
    const id = this.viewDownloadRequest!.id;
    if (!id) {
      this.displayNotificationMessage('error', `Invalid download request id.`);
      return;
    }

    this.displayProgressBar(true);
    const isExpedite = this.viewDownloadRequest!.priority != this.EXPEDITED_PRIORITY;
    const serviceCall = isExpedite
      ? this._downloadRequestService.expediteDownloadRequest(id)
      : this._downloadRequestService.downgradeDownloadRequest(id);

    const updatedPriorityValue = isExpedite ? this.EXPEDITED_PRIORITY : this.DEFAULT_PRIORITY;
    const successMessage = isExpedite
      ? 'Download request successfully expedited.'
      : 'Download request successfully downgraded.';

    serviceCall.then(result => {
      if (result.status) {
        this.viewDownloadRequest!.priority = updatedPriorityValue;
        this.displayNotificationMessage('success', successMessage);
      }
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  protected async getContentDetails() {
    await this.loadData();
  }

  protected initFormGroup(): void {
  }

  protected loadDropDownData(): void {
  }

  protected override saveOrUpdate() {
    return new Promise<OperationStatus>((resolve, reject) => {
    })
  }

  protected async loadData() {
    // Get download request fields data
    let promises: Promise<any>[] = [];
    promises.push(this._downloadRequestService.getDownloadRequestThresholds().then(result => {
      this.maxExpediteDownloadRequestsCount = this.getMaxExpediteDownloadRequestCount(result);
    }));
    promises.push(this._downloadRequestService.getOneDownloadRequest(Number(this.pageContext.dri_f)).then(result => {
      this.downloadRequest = result;
      this.viewDownloadRequest = this.downloadRequestUtil.mapDownloadRequestToView(result);
      if (this.viewDownloadRequest?.downloadParameters) {
        this.downloadRequestParameters = JSON.parse(this.viewDownloadRequest?.downloadParameters);
      }
      this.getDownloadType(this.downloadRequestParameters?.report_type);
      this.getLastExpeditedByMarketCode(this.viewDownloadRequest?.country!);
      this.getDownloadRequestMetrics(this.viewDownloadRequest.id);
      this.downloadRequestFields = [
        {key: 'Period', value: this.viewDownloadRequest.period!, cols: 1, rows: 1,},
        {key: 'Country', value: this.viewDownloadRequest.country!, cols: 1, rows: 1,},
        {key: 'Product Type', value: this.viewDownloadRequest.productType!, cols: 1, rows: 1,},

        {key: 'Product', value: this.viewDownloadRequest.product!, cols: 1, rows: 1,},
        {key: 'Sponsor', value: this.viewDownloadRequest.sponsor!, cols: 1, rows: 1,},
        {key: 'Language', value: this.viewDownloadRequest.language!, cols: 1, rows: 1,},

        {key: 'Requested By', value: this.viewDownloadRequest.requestedBy!, cols: 1, rows: 1,},
        {key: 'Request Date', value: new Date(this.viewDownloadRequest.requestDate!), cols: 1, rows: 1,},
        {key: 'Start', value: new Date(this.viewDownloadRequest.processStartDate!), cols: 1, rows: 1,},

        {key: 'End', value: new Date(this.viewDownloadRequest.processEndDate!), cols: 1, rows: 1,},
        {key: 'Status', value: this.viewDownloadRequest.processStatus!, cols: 1, rows: 1,},
        {key: 'SharePoint Folder', value: this.viewDownloadRequest.sharepointFolderUrl!, cols: 1, rows: 1,},

        {key: 'Parameters', value: JSON.stringify(this.viewDownloadRequest.downloadParameters, null, 4), cols: 1, rows: 3,},
        {key: 'Message', value: this.viewDownloadRequest.processStatusMessage!, cols: 1, rows: 3,},
      ];
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => this.displayProgressBar(false)));
    await Promise.all(promises);
  }

  protected override getPageState(): any {
    return this.pageContext;
  }

  private getDownloadRequestMetrics(downloadRequestId?: number) {
    this._downloadRequestService.getDownloadMetrics([downloadRequestId])
      .then(result => {
        result.forEach(metric => {
          if (metric.id !== undefined && metric.id === downloadRequestId) {
            this.downloadRequestMetrics = metric;
          }
        });
      })
      .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  private getDownloadType(downloadType: string) {
    this._lookupService.getDownloadRequestTypes().then(result => {
      result.forEach(item => {
        if (item.downloadRequestTypeValue === downloadType) {
          this.downloadType = item;
        }
      })
    });
  }

  private getMaxExpediteDownloadRequestCount(resultSetThresholds: ResultsetThresholds) {
    const maxExpediteThreshold = resultSetThresholds.thresholds.find(result => result.thresholdCode === this.MAX_EXPEDITE_THRESHOLD_CODE);
    if (maxExpediteThreshold) {
      return maxExpediteThreshold.threshold;
    }
    return 0;
  }

  private getLastExpeditedByMarketCode(country: string) {
    this._downloadRequestService.getLastExpeditedByMarketCode(country).then(result => {
      this.currentMarketExpediteCount = result.value;
    });
  }
}
