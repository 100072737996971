import {Component, Input} from "@angular/core";
import {DownloadRequest, DownloadRequestData, DownloadRequestTemplate, ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DetailBaseComponent} from "@pages/detail.base.component";
import {DataItem, OperationStatus, ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {ReferenceService} from "@data/reference/reference.service";
import {LookupService} from "@data/lookup/lookup.service";
import {ViewIndustry, ViewMarket} from "@data/interefaces/reference.interfaces";
import {DownloadRequestType, LookupLocale, Period, StandardReportType, SurveySubject} from "@data/interefaces/lookup.interfaces";
import {
  setCurrentMarketExpediteCount,
  setIsUserAdmin,
  setMaxDownloadRequestExpediteCount,
  setThresholdCount,
  tableColumns
} from "@pages/download-request/download-request-add/download-request-add.component.ds";
import {AgGridService} from "@shared/services/ag-grid.service";
import {AgCheckboxTreeService} from "@shared/ag-checkbox-tree/services/ag-checkbox-tree.service";
import {GridApi, GridOptions, IRowNode} from "ag-grid-enterprise";
import {ColumnVisibleEvent, GridReadyEvent, SelectionChangedEvent} from "ag-grid-community";
import {ReportType, RespondentSegmentLabel, SubjectLabel, WeightedProductLabel} from "@data/interefaces/product.interfaces";
import {TreeSelectionChanged} from "@shared/ag-checkbox-tree/ag-checkbox-tree.component.ds";
import {AgCheckboxListService} from "@shared/ag-checkbox-list/services/ag-checkbox-list.service";
import {CheckboxListSelectionChanged} from "@shared/ag-checkbox-list/ag-checkbox-list.component.ds";
import {BehaviorSubject, Subscription} from "rxjs";
import {PptDownloadsService} from "@data/ppt-downloads/ppt-downloads.service";
import {NotificationType, SelectionChangeType} from "@data/enums/data.enums";
import {Util} from "@data/util/util";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {expediteClickAction} from "@pages/download-request/download-request-list.component.ds";

@Component({
  selector: 'download-request-add',
  templateUrl: './download-request-add.component.html',
  styleUrls: ['./download-request-add.component.scss'],
  providers: [AgGridService, AgCheckboxTreeService, AgCheckboxListService]
})
export class DownloadRequestAddComponent extends DetailBaseComponent {
  @Input() downloadRequestsToRetry?: ViewDownloadRequest[];
  periods: Period[] = [];
  markets: ViewMarket[] = [];
  industries: ViewIndustry[] = [];
  surveySubjects: SurveySubject[] = [];
  downloadRequestTypes: DownloadRequestType[] = [];
  locales: LookupLocale[] = [];
  products: WeightedProductLabel[] = [];
  selectedProductsAndSponsors: Map<WeightedProductLabel, SubjectLabel[]> = new Map();
  selectedSponsors: SubjectLabel[] = [];
  selectedRespondents: RespondentSegmentLabel[] = [];
  downloadParameters: string = "";
  rowData: DownloadRequestData[] = [];
  respondentsList: RespondentSegmentLabel[] = [];
  pendingDownloadRequests = new BehaviorSubject<any>({});
  gridApi!: GridApi;
  standardReportTypes: StandardReportType[] = [];
  reportTypes: ReportType[] = [];
  selectedRows: DownloadRequestData[] = [];
  requestsToCheckForDuplicates: DownloadRequestData[] = [];
  disableProductDropdown: boolean = false;
  protected isSaving = false;
  protected override formGroup: FormGroup<{ [K in keyof DownloadRequestTemplate]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override pageContext: {
    p_f: string | undefined,
    m_f: string | undefined,
    i_f: string | undefined,
    ss_f: string | undefined,
    dt_f: string | undefined,
    l_f: string | undefined,
    pr_f: string | undefined,
  } = {
    p_f: undefined,
    m_f: undefined,
    i_f: undefined,
    ss_f: undefined,
    dt_f: undefined,
    l_f: undefined,
    pr_f: undefined,
  }
  protected readonly tableColumns = tableColumns;
  protected gridOptions?: GridOptions;
  private cachedFormValues?: Partial<DownloadRequestTemplate>;
  private formGroupValueChangeSubscription?: Subscription;
  private productsSubscription?: Subscription;
  private parametersSubscription?: Subscription;
  private maxNumberOfRequestsThreshold: number = 0;
  private maxNumberOfExpeditedRequestsPerMarketCode: number = 0;
  private currentExpeditedCountByMarket: number = 0;
  private currentBatchCode: string = '';
  private originalBatchCodeFromRetry?: string = '';
  private readonly MAX_EXPEDITE_THRESHOLD_CODE: string = 'max-expedite-count';
  private readonly DEFAULT_PRIORITY: number = 5;
  private readonly EXPEDITED_PRIORITY: number = 1;
  private isRetryMode: boolean = false;

  constructor(private _agGridService: AgGridService, private _checkboxTreeService: AgCheckboxTreeService, private _checkboxListService: AgCheckboxListService,
              private _referenceService: ReferenceService, private _lookupService: LookupService, private _pptDownloadService: PptDownloadsService, private _downloadRequestService: DownloadRequestService) {
    super();
    this.title = "Create New Download Request(s)"
  }

  override async ngOnInit() {
    super.ngOnInit();
    this._agGridService.updateColumnsFromLocalStorage(this.pageName, this._agGridService.getGridColumns(this.tableColumns));

    this.initGridOptions();
    this.initFormGroup();
    await this.loadDropDownDataAsync();
    this.loading = false;
    this.initSubscriptions();
    this.initThresholds();
    setIsUserAdmin(this._store.isAdmin);
    await this.initRetryMode();
  }

  ngOnDestroy() {
    this.formGroupValueChangeSubscription?.unsubscribe();
    this.productsSubscription?.unsubscribe();
    this.parametersSubscription?.unsubscribe();
  }

  onColumnVisible(event: ColumnVisibleEvent<any>) {
    this._agGridService.onColumnVisibleHandler(event, this.pageName);
  }

  requestClick() {
    if (this.canRequestDownloads()) {
      this.displayProgressBar(true);
      this._pptDownloadService.saveDownloadRequests(this.selectedRows).then(result => {
          this.displayNotificationMessage('info', 'Successfully sent download requests for processing');
          this.close();
        }
      ).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
      ).finally(() => this.displayProgressBar(false));
    } else {
      this.close();
    }
  }

  async onCheckBoxTreeSelectionChanged(event: TreeSelectionChanged) {
    if (event.changeType === SelectionChangeType.ADD) {
      // Adding records and make the service call.
      const subjectIds: string[] = [];
      // we only want to focus on the events in this event, and not from the entire list from selectedSponsors
      event.childrenItems.forEach(item => {
        // Add the subject id to the array so we can use it in the service call.
        subjectIds.push(item.subjectId.toString());
        // Check to make sure the selected item doesn't already exist.  If it does, add it.
        if (!this.selectedSponsors.some(sponsor => sponsor.subjectId === item.subjectId)) {
          this.selectedSponsors.push(item);
        }
      });

      if (this.formGroup.controls.downloadRequestType?.value.requiresRespondents) {
        this.displayProgressBar(true);
        await this._pptDownloadService.getRespondentSegmentLabels(event.parentItem.productPeriodId, event.parentItem.productLocale, subjectIds.join(',')).then(
          result => {
            // Add to the respondents list the results.  We want to track what's already in there by it's label and code.  If we don't find it,
            // add it directly to the list.  If we do find it, we want to ensure the subjectId's are updated to reflect the new list coming in
            result.forEach(item => {
              const existingRespondentSegment = this.respondentsList.find(rs => rs.respondentSegmentCode.toLowerCase() === item.respondentSegmentCode.toLowerCase()
                && rs.respondentSegmentLabel.toLowerCase() === item.respondentSegmentLabel.toLowerCase());
              if (existingRespondentSegment) {
                // respondent segment already exist in the list, so lets append the subjectIds.  Find the subjectIds that are not already in the list
                const subjectIdsToAdd = item.subjectIds.filter(id => !existingRespondentSegment.subjectIds.includes(id));
                existingRespondentSegment.subjectIds.push(...subjectIdsToAdd);
                // Sort the list for consistency
                existingRespondentSegment.subjectIds.sort((a, b) => a - b);
              } else {
                this.respondentsList.push(item);
              }
            });
            this.createAgCheckBoxListWithSelectedValues();
            this.displayProgressBar(false);
          }).catch(error => {
          this.displayProgressBar(false);
          this.displayNotificationMessage('error', JSON.stringify(error));
        });
      }
    } else {
      // Remove from the selectedSponsors list
      event.childrenItems.forEach(item => {
        this.removeSponsorFromSelectedSponsors(item);
      });
      // Delete from respondents list by checking the current respondentsList to see if any of the items has the subject id that i've unselected.
      // If it does and it's the only subjectId in the list, remove it.  Otherwise, just remove the subjectId from the list.
      // const respondentsMatchingSubjectId = this.respondentsList.filter(item => item.subjectIds.includes(event.parentItem.subjectId));
      const respondentsMatchingSubjectId = this.respondentsList.filter(item => event.childrenItems.some(sponsor => item.subjectIds.includes(sponsor.subjectId)));
      // update all of the matching respondents subjectIds to remove the ones from the returned list.
      respondentsMatchingSubjectId.forEach(item => {
        item.subjectIds = item.subjectIds.filter(id => !event.childrenItems.some(sponsor => sponsor.subjectId === id));
      });
      // Remove any of the items that don't have a subjectId in the list
      this.respondentsList = this.respondentsList.filter(respondents => respondents.subjectIds.length > 0);
      // redraw the tree.
      this.createAgCheckBoxListWithSelectedValues();
    }
    this.addOrUpdateDownloadRequest(event.changeType);
  }

  onCheckBoxListSelectionChanged(event: CheckboxListSelectionChanged) {
    if (event.changeType === SelectionChangeType.ADD) {
      event.items.forEach(item => {
        // Check to make sure the selected item doesn't already exist.  If it does add it
        if (!this.selectedRespondents.some(r => r.respondentSegmentId === item.tag.respondentSegmentId)) {
          this.selectedRespondents.push(item.tag);
        }
      });
      this.selectedRespondents.sort((a, b) => a.respondentSegmentId - b.respondentSegmentId);
    } else if (event.changeType === SelectionChangeType.DELETE) {
      event.items.forEach(item => {
        // Check to see if it exists.. if so remove it
        const index = this.selectedRespondents.findIndex(r => r.respondentSegmentId === item.tag.respondentSegmentId);
        // Item found in list, remove it
        if (index !== -1) {
          this.selectedRespondents.splice(index, 1);
        }
      });
    } else if (event.changeType === SelectionChangeType.REFRESH) {
      // do nothing for now
    }
    this.addOrUpdateDownloadRequest(event.changeType);
  }

  async onClearFiltersButtonClick() {
    let resetFilters = true;
    if (this.isDownloadRequestSelectionInProgress()) {
      const dialogMessage = 'Clearing the filters will reset all of your current progress.  Are you sure you want to continue?';
      resetFilters = await this.displayClearSelectionWarningMessage(dialogMessage);
    }
    if (resetFilters) {
      this.resetDropDownFilterValues();
      this.resetSelectedDownloadRequestValues();
    }
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    // when setting via the api using setSelectedNodes these api and rowDataChanged sources are fired.  We want to ignore them.
    if (event.source !== 'api' && event.source !== 'rowDataChanged') {
      this.selectedRows = this.gridApi.getSelectedRows();
    }
    // need to move refreshHeader() call to outside of the initial if statement, because in rare circumstances when clicking
    // check boxes too quickly in the grid, the event.source type becomes 'api' instead of 'checkboxSelected'.
    this.gridApi.refreshHeader();
  }

  canRequestDownloads() {
    return this.selectedRows.length > 0;
  }

  onItemsSelected(event: any[]) {
    this.handleProductChanged(event);
  }

  canShowProductSelection() {
    return this.products.length > 0;
  }

  canShowSponsorSelection() {
    return this.canShowProductSelection() && this.selectedProductsAndSponsors.size > 0;
  }

  canShowRespondentSelection() {
    return this.canShowSponsorSelection() && this.selectedSponsors.length > 0;
  }

  canShowResultsGrid() {
    // show the grid once all other criteria is met
    return this.canShowRespondentSelection();
  }

  async closeClick() {
    let closeScreen = true;
    if (this.isDownloadRequestSelectionInProgress()) {
      const dialogMessage = 'Are you sure you want to close the screen?  All progress will be lost.';
      closeScreen = await this.displayClearSelectionWarningMessage(dialogMessage);
    }
    if (closeScreen) {
      this.close();
    }
  }

  protected override close() {
    const urlSegments = this.goBackUrl.split('/');
    this._router.navigate(urlSegments);
  }

  protected override loadDropDownData(): void {

  }

  protected async loadDropDownDataAsync(): Promise<void> {
    let promises: Promise<any>[] = [];
    promises.push(this._referenceService.getAllMarkets().then(result => {
      result.forEach(item => this.markets.push(item));
    }));
    promises.push(this._referenceService.getAllIndustries().then(result => {
      result.forEach(item => this.industries.push(item));
    }));
    promises.push(this._lookupService.getSurveySubjects().then(result => {
      result.forEach(item => this.surveySubjects.push(item));
    }));
    promises.push(this._lookupService.getPeriods().then(result => {
      result.forEach(item => this.periods.push(item));
    }));
    promises.push(this._lookupService.getLookupLocales().then(result => {
      result.forEach(item => this.locales.push(item));
    }));
    promises.push(this._lookupService.getDownloadRequestTypes().then(result => {
      result.forEach(item => this.downloadRequestTypes.push(item));
    }));
    promises.push(this._lookupService.getStandardReportTypes().then(result => {
      result.forEach(item => this.standardReportTypes.push(item));
    }));
    promises.push(this._pptDownloadService.getReportType().then(result => {
      result.forEach(item => this.reportTypes.push(item));
    }));
    await Promise.all(promises);
  }

  protected override getContentDetails(): void {
  }

  protected override saveOrUpdate(): Promise<OperationStatus> | Promise<DataItem> {
    throw new Error("Method not implemented.");
  }

  protected getFormControl(controlName: (keyof DownloadRequestTemplate)) {
    return this.formGroup.get(controlName) as FormControl<any>;
  }

  protected override initFormGroup() {
    this.formGroup = new FormGroup<{ [K in keyof DownloadRequestTemplate]?: FormControl<any> }>(
      {
        downloadRequestType: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        period: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        market: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        industry: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        surveySubject: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        locale: new FormControl({
          value: "",
          disabled: false
        }, [Validators.required]),
        products: new FormControl({
          value: [],
          disabled: true
        })
      }
    );
  }

  protected onGridReady(event: GridReadyEvent<any>) {
    this.gridApi = event.api;
    this.gridApi.addEventListener(expediteClickAction, (event: any) => {
      this.onExpediteClick(event.detail.rowData);
    });
  }

  protected onModelUpdated() {
    this.gridApi?.refreshHeader();
  }

  private onExpediteClick(downloadRequest: ViewDownloadRequest) {
    const isExpedite = downloadRequest.priority != this.EXPEDITED_PRIORITY;
    // update current value internally
    isExpedite ? this.currentExpeditedCountByMarket++ : this.currentExpeditedCountByMarket--;
    setCurrentMarketExpediteCount(this.currentExpeditedCountByMarket);
    downloadRequest.priority = isExpedite ? this.EXPEDITED_PRIORITY : this.DEFAULT_PRIORITY;
    this.gridApi.refreshCells({
      force: true,
      columns: ["priority"]
    });
  }

  private removeSponsorFromSelectedSponsors(sponsorToRemove: SubjectLabel) {
    const index = this.selectedSponsors.findIndex(sponsor => sponsor.subjectId === sponsorToRemove.subjectId);
    if (index !== -1) {
      this.selectedSponsors.splice(index, 1);
    }
  }

  private removeSponsorFromRespondentsList(sponsor: SubjectLabel) {
    this.respondentsList.forEach(respondent => {
      const index = respondent.subjectIds.indexOf(sponsor.subjectId);
      if (index !== -1) {
        respondent.subjectIds.splice(index, 1);
        respondent.subjectIds.sort((a, b) => a - b);
      }
    });
  }

  /**
   * Checks if the download request has any pending changes
   */
  private isDownloadRequestSelectionInProgress() {
    // if any sponsors or respondents are selected, return true;
    return (this.selectedSponsors.length > 0 || this.selectedRespondents.length > 0);
  }

  private async displayClearSelectionWarningMessage(message: string) {
    return await this.displayDialogMessage(NotificationType.WARNING, message);
  }

  private initSubscriptions() {
    this.formGroupValueChangeSubscription = this.formGroup.valueChanges.subscribe(async (value) => await this.handleValueChanged(value));
    this.productsSubscription = this.formGroup.controls.products?.valueChanges.subscribe(value => this.handleProductChanged(value));
    this.parametersSubscription = this.pendingDownloadRequests.subscribe(params => this.handleDownloadRequestDataChanged(params.items, params.changeType));
  }

  private initGridOptions() {
    // Setup the sidebar to only have hide / show columns.  There is no filtering here.
    const defaultColDefs = this._agGridService.getDefaultColumnDefinition();
    // remove the filters
    defaultColDefs.filter = false;
    defaultColDefs.floatingFilter = false;
    const options = this._agGridService.getDefaultAgiGridOptions();
    options.defaultColDef = defaultColDefs;
    const sideBar = this._agGridService.getDefaultSideBar();
    sideBar.toolPanels = [this._agGridService.getDefaultColumnsSideBarPanel()];
    options.sideBar = sideBar;
    this.gridOptions = options;
  }

  private initThresholds() {
    this._pptDownloadService.getNewDownloadRequestThresholds().then(result => {
      this.maxNumberOfRequestsThreshold = result.count;
      setThresholdCount(this.maxNumberOfRequestsThreshold);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    this._downloadRequestService.getDownloadRequestThresholds().then(result => {
      this.maxNumberOfExpeditedRequestsPerMarketCode = this.getMaxExpediteDownloadRequestCount(result);
      setMaxDownloadRequestExpediteCount(this.maxNumberOfExpeditedRequestsPerMarketCode);
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  private async initRetryMode() {
    if (this.downloadRequestsToRetry) {
      // download requests to try were passed into this form, which means we must prepopulate fields.
      if (this.validateDownloadRequestsToRetry(this.downloadRequestsToRetry)) {
        // Start populating data
        this.isRetryMode = true;
        await this.processDownloadRequestsToRetry(this.downloadRequestsToRetry);
      } else {
        this.displayNotificationMessage('error', 'Invalid download requests were sent to the page.  Cannot retry the selected requests.');
      }
    }
  }

  private async handleValueChanged(value: Partial<DownloadRequestTemplate>) {
    // check if market code has changed and if so, get the updated threshold limit
    this.handleMarketCodeChange(value);
    // determine if we need to get a new batch code
    await this.handleBatchCodeChange(value);
    // check if all the values from the dropdowns are selected.  If so, enable the control
    let enableProductDropdown = this.handleEnableProductDropdown(value);
    // once it's enabled, check to see if any of the values of the dropdowns have change (or are new) and make the request to get the products
    if (enableProductDropdown) {
      // only make webservice call if the original values have not changed
      if (this.validateIfRequiredProductDropdownValuesChanged(this.formGroup.value)) {
        if (this.isDownloadRequestSelectionInProgress()) {
          const dialogMessage = 'Changing the selection will cause your current progress to reset.  Are you sure you want to continue?';
          if (await this.displayClearSelectionWarningMessage(dialogMessage)) {
            // ok was clicked
            this.resetControlsAndGetWeightedProductLabels();
          } else {
            // clicked no make sure the dropdowns are reset to the originals
            this.resetDropDownsToCachedValues();
          }
        } else {
          this.resetControlsAndGetWeightedProductLabels();
        }
      }
    } else {
      // Only fire the reset products if it's not an empty list.
      // Otherwise, this will keep firing the valueChanges subscription for products.
      if (Array.isArray(this.products) && this.products.length > 0) {
        this.products = [];
      }
    }

    this.cachedFormValues = this.formGroup.value;
  }

  private resetControlsAndGetWeightedProductLabels() {
    this.resetSelectedDownloadRequestValues();
    this.getWeightedProductLabels();
  }

  private async getWeightedProductLabels() {
    this.displayProgressBar(true);
    try {
      const result = await this._pptDownloadService.getWeightedProductLabels(this.formGroup.controls.templateVersion?.value, this.formGroup.controls.period?.value,
        this.formGroup.controls.market?.value.marketId, this.formGroup.controls.industry?.value.industryId,
        this.formGroup.controls.surveySubject?.value, this.formGroup.controls.locale?.value);
      // new products coming in, clear the current list
      this.products = [];
      result.forEach(item => {
        // set the display value as it comes off the pipe.
        item.displayValue = item.productCode + '-' + item.productLabel + '-' + item.weightedProductLabel;
        this.products.push(item);
      });
    } catch (error: any) {
      const serializedError = JSON.stringify(error);
      let errorMessage = serializedError;
      if (serializedError && serializedError === "{}") {
        errorMessage = error.toString();
      }
      this.displayNotificationMessage('error', errorMessage);
    } finally {
      this.displayProgressBar(false);
    }
  }

  private handleEnableProductDropdown(value: Partial<DownloadRequestTemplate>) {
    const requiredControls = [
      this.formGroup.controls.downloadRequestType,
      this.formGroup.controls.period,
      this.formGroup.controls.market,
      this.formGroup.controls.industry,
      this.formGroup.controls.surveySubject,
      this.formGroup.controls.locale
    ];
    return requiredControls.every(c => c?.value);
  }

  private handleMarketCodeChange(value: Partial<DownloadRequestTemplate>) {
    if (this.hasMarketCodeValueChanged(value)) {
      this._downloadRequestService.getLastExpeditedByMarketCode(value.market.marketCode).then(result => {
        this.currentExpeditedCountByMarket = result.value;
        setCurrentMarketExpediteCount(this.currentExpeditedCountByMarket);
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
  }

  private async handleBatchCodeChange(value: Partial<DownloadRequestTemplate>) {
    if (this.hasMarketCodeValueChanged(value) && value?.surveySubject) {
      // the marketCode has changed and surveySubject is not null or empty we need to get a new batch code
      this.currentBatchCode = await this.getBatchCode(value);
      return;
    }

    if (this.hasSurveySubjectValueChanged(value) && value?.market?.marketCode) {
      // the survey subject has changed and the market code is not null or empty we need to get a new batch code
      this.currentBatchCode = await this.getBatchCode(value);
      return;
    }
  }

  private hasMarketCodeValueChanged(value: Partial<DownloadRequestTemplate>) {
    return value?.market?.marketCode && value?.market?.marketCode !== this.cachedFormValues?.market?.marketCode;
  }

  private hasSurveySubjectValueChanged(value: Partial<DownloadRequestTemplate>) {
    return value?.surveySubject && value?.surveySubject !== this.cachedFormValues?.surveySubject;
  }

  private validateIfRequiredProductDropdownValuesChanged(value: Partial<DownloadRequestTemplate>) {
    return !(this.cachedFormValues?.downloadRequestType === value.downloadRequestType &&
      this.cachedFormValues?.period === value.period &&
      this.cachedFormValues?.market.marketId === value.market.marketId &&
      this.cachedFormValues?.industry.industryId === value.industry.industryId &&
      this.cachedFormValues?.surveySubject === value.surveySubject &&
      this.cachedFormValues?.locale === value.locale);
  }

  private async handleProductChanged(value: WeightedProductLabel[]) {
    if (value && Array.isArray(value) && value.length > 0) {
      const promises = value.map(async (product) => {
        if (!this.selectedProductsAndSponsors.has(product)) {
          // product not found in the list, add it
          this.displayProgressBar(true);
          await this._pptDownloadService.getSubjectLabels(product.productPeriodId.toString(), product.productLocale).then(result => {
            this.selectedProductsAndSponsors.set(product, result);
          }).catch(error => {
            this.displayNotificationMessage('error', JSON.stringify(error));
          }).finally(() => this.displayProgressBar(false));
        } else {
          // value returns a list of all items selected, so since we are removing items.. we need to remove everything from selectedProductsAndSponsors.key
          // where it's not in that list.
          const productPeriodsToKeep = new Set(value.map(v => v.productPeriodId));
          Array.from(this.selectedProductsAndSponsors.entries()).forEach(([key, value]) => {
            if (!productPeriodsToKeep.has(key.productPeriodId)) {
              this.selectedProductsAndSponsors.delete(key);
              // remove the sponsors as well
              value.forEach(sponsor => {
                this.removeSponsorFromSelectedSponsors(sponsor);
                // I may also have to remove the respondents here as well.
                this.removeSponsorFromRespondentsList(sponsor);
              });
              // since we are deleting this item, lets make sure it's not in the grid, if it is, we need to remove it there as well.
              this.addOrUpdateDownloadRequest(SelectionChangeType.DELETE);
            }
          });
        }
      });
      await Promise.all(promises);
      this.createAgCheckBoxTreeWithSelectedValues();
    }
    // value is 0, which means we may have unselected all items, so lets make sure there are items still in the selectedProductsAndSponsors.
    if (value?.length === 0 && this.selectedProductsAndSponsors.size > 0) {
      // clear and update the tree if items are all unselected
      this.resetProductsSponsorsAndRespondents();
    }
  }

  private addOrUpdateDownloadRequest(changeType: SelectionChangeType) {
    const downloadRequests = this.createNewDownloadRequestParams();
    // I have the parameters now I need to map them to the grids dataSource
    this.pendingDownloadRequests.next({items: downloadRequests, changeType: changeType});
  }

  private getStandardReportTypeById(standardReportTypeId: number) {
    const standardReportType = this.standardReportTypes.find(reportType => reportType.standardReportTypeId === standardReportTypeId);
    if (!standardReportType) {
      // Should only be a dev exception
      throw new Error(`Standard report type with ID: ${standardReportTypeId} not found.`);
    }
    return standardReportType;
  }

  private setDownloadRequestProduct(downloadRequest: DownloadRequestData, selectedProduct?: WeightedProductLabel) {
    if (downloadRequest && selectedProduct) {
      downloadRequest.productCode = selectedProduct.productCode;
      downloadRequest.weightedProductLabel = selectedProduct.displayValue;
      downloadRequest.productPeriodId = selectedProduct.productPeriodId.toString();
      downloadRequest.productViewId = selectedProduct.productViewId.toString();
      downloadRequest.productViewIdList = selectedProduct.productViewIdList?.toString();
      downloadRequest.sponsorProductSaleAccessId = selectedProduct.sponsorProductSaleAccessId.toString();
    }
  }

  private setDownloadRequestDashboardVersion(downloadRequest: DownloadRequestData, standardReportTypeId: number) {
    const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
    const periodString = standardReportType.period.toString();
    downloadRequest.dashboardPrefix = `${periodString.substring(periodString.length - 2)}-`;
  }

  private setDownloadRequestHighlightSegment(downloadRequest: DownloadRequestData, standardReportTypeId: number) {
    const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
    if (standardReportType.period === 2022 || standardReportType.period === 2023) {
      downloadRequest.highlighted = '0';
    } else {
      downloadRequest.highlighted = '1';
    }
  }

  private setDownloadRequestSponsor(downloadRequest: DownloadRequestData, selectedSponsor: SubjectLabel) {
    downloadRequest.sponsorId = selectedSponsor.subjectId.toString();
    downloadRequest.sponsorCode = selectedSponsor.subjectCode;
    downloadRequest.sponsorLabel = selectedSponsor.subjectLabel;
    downloadRequest.standardReportTypeId = selectedSponsor.standardReportTypeId.toString();
    downloadRequest.approvalStatus = selectedSponsor.approvalStatus;
  }

  private setDownloadRequestRespondents(downloadRequest: DownloadRequestData, selectedRespondents: RespondentSegmentLabel[]) {
    downloadRequest.respondentSegments = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentId);
    downloadRequest.respondentCompanyLabel = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentLabel).join(';');
    downloadRequest.respondentCompanyCode = selectedRespondents.map(selectedRespondent => selectedRespondent.respondentSegmentCode).join(';');
  }

  private setDownloadRequestTableauProject(downloadRequest: DownloadRequestData) {
    downloadRequest.tableauProject = "";
  }

  private createNewDownloadRequestParams(): DownloadRequestData[] {
    // the Download request is all about the selected sponsors.  Each selected sponsors will be a new download request.
    const downloadRequests: DownloadRequestData[] = [];
    this.selectedSponsors.forEach(selectedSponsor => {
      // check the selectedSponsors to see if any respondents were selected.
      const selectedRespondents = this.selectedRespondents.filter(selectedRespondent => selectedRespondent.subjectIds.some(id => id === selectedSponsor.subjectId));

      // If there are some selected, we can proceed with creating the item.  If not, we don't want to add it yet as not all the
      // information is available to create a request.
      let selectedProduct: WeightedProductLabel | undefined;
      const downloadRequest: DownloadRequestData = this.createDownloadRequestWithDefaultValues();
      for (const [key, value] of this.selectedProductsAndSponsors.entries()) {
        if (value.includes(selectedSponsor)) {
          selectedProduct = key;
          break;
        }
      }
      if (selectedProduct) {
        this.setDownloadRequestProduct(downloadRequest, selectedProduct);
        this.setDownloadRequestSponsor(downloadRequest, selectedSponsor);
        this.setDownloadRequestDashboardVersion(downloadRequest, selectedSponsor.standardReportTypeId);
        this.setDownloadRequestHighlightSegment(downloadRequest, selectedSponsor.standardReportTypeId);
        this.setDownloadRequestReportTypeValues(downloadRequest, selectedSponsor);
        this.setDownloadRequestTableauProject(downloadRequest);
        this.setDownloadRequestRespondents(downloadRequest, selectedRespondents);
        if ((selectedRespondents && selectedRespondents.length > 0) || !this.formGroup.controls.downloadRequestType?.value.requiresRespondents) {
          downloadRequests.push(downloadRequest);
        }
      }
    });
    return downloadRequests;
  }

  private createDownloadRequestWithDefaultValues() {
    const downloadRequest: DownloadRequestData = {
      marketId: this.formGroup.controls.market?.value.marketId,
      marketCode: this.formGroup.controls.market?.value.marketCode,
      marketLabel: this.formGroup.controls.market?.value.marketName,
      industryId: this.formGroup.controls.industry?.value.industryId,
      industryCode: this.formGroup.controls.industry?.value.industryCode,
      industryLabel: this.formGroup.controls.industry?.value.industryName,
      language: this.formGroup.controls.locale?.value,
      period: this.formGroup.controls.period?.value,
      downloadRequestTypeId: this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeId,
      downloadRequestTypeValue: this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue,
      downloadRequestTypeLabel: this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeDisplayValue,
      surveySubject: this.formGroup.controls.surveySubject?.value,
      priority: this.DEFAULT_PRIORITY,
      batchCode: this.currentBatchCode,
      originalBatchCode: this.originalBatchCodeFromRetry ? this.originalBatchCodeFromRetry : undefined
    };
    return downloadRequest;
  }

  private async handleDownloadRequestDataChanged(params: DownloadRequestData[], changeType: SelectionChangeType) {
    if (this.rowData.length === 0) {
      // No data yet, so take whatever params is and set it.
      this.rowData = params ? params : [];
      this.gridApi?.setGridOption('rowData', this.rowData);
      this.gridApi?.selectAllFiltered();
      const messageColumnData = await this.getMessageColumnData(params);
      this.processMessageColumnResults(messageColumnData, params);
      return;
    }

    if (params.length === 0) {
      // nothing is here, so lets reset the grid
      this.gridApi.deselectAll();
      this.rowData = [];
      return;
    }

    if (changeType === SelectionChangeType.ADD || changeType === SelectionChangeType.REFRESH) {
      this.addMatchingDownloadRequestToGrid(params)
    } else if (changeType === SelectionChangeType.DELETE) {
      this.removeMatchingDownloadRequestFromGrid(params);
    }
    const messageColumnData = await this.getMessageColumnData(this.requestsToCheckForDuplicates);
    this.processMessageColumnResults(messageColumnData, this.requestsToCheckForDuplicates);
    this.requestsToCheckForDuplicates = []; // clear list after we're doing processing
  }

  private addMatchingDownloadRequestToGrid(downloadRequests: DownloadRequestData[]) {
    const newItemsAdded: DownloadRequestData[] = [];
    for (const request of downloadRequests) {
      if (!this.updatedRespondentListAndRefreshCells(request)) {
        this.rowData.push(request);
        newItemsAdded.push(request);
        this.addDownloadRequestToUpdateList(request);
      }
    }

    if (newItemsAdded && newItemsAdded.length > 0) {
      const transactionResult = this.gridApi.applyTransaction({add: newItemsAdded});
      if (transactionResult && transactionResult.add) {
        transactionResult.add.forEach(addedNode => {
          addedNode.setSelected(true);
        });
        // manually fire as my sourceType so the handler only processes it once
        this.onRowSelectionChanged({source: 'checkboxSelected', type: 'selectionChanged', api: this.gridApi} as SelectionChangedEvent);
      }
    }
  }

  private removeMatchingDownloadRequestFromGrid(downloadRequests: DownloadRequestData[]) {
    // find the items that do not exist in rowData
    const missingItems = this.rowData.filter(
      rowItem => !downloadRequests.some(request => this.compareDownloadRequestsForEquality(rowItem, request))
    );

    if (missingItems && missingItems.length > 0) {
      const currentSelection = this.gridApi.getSelectedNodes().map(node => node.data);
      this.rowData = this.rowData.filter(rowItem => !missingItems.includes(rowItem));
      // I have to use setGridOptions when removing the items.  When using applyTransaction (like in the add method)
      // The grid re-renders too slowly for me to reselect.  By using setGridOptions the grid is re-rendered to a state where I can select the nodes
      this.gridApi.setGridOption('rowData', this.rowData);
      const nodesToReselect: IRowNode<any>[] = [];
      this.gridApi.forEachNode(node => {
        if (currentSelection.find(item => this.compareDownloadRequestsForEquality(node.data, item))) {
          nodesToReselect.push(node);
        }
      });
      this.gridApi.setNodesSelected({nodes: nodesToReselect, newValue: true});
      // manually fire as my sourceType so the handler only processes it once
      this.onRowSelectionChanged({source: 'checkboxSelected', type: 'selectionChanged', api: this.gridApi} as SelectionChangedEvent);
    }

    // everything is removed, so lets update the respondents list
    for (const request of downloadRequests) {
      this.updatedRespondentListAndRefreshCells(request);
    }
  }

  private updatedRespondentListAndRefreshCells(request: DownloadRequestData) {
    const matchingDownloadRequestRecord = this.getMatchingDownloadRequestRecordFromDataSource(request);
    if (matchingDownloadRequestRecord) {
      // record found, lets check to see if we need to update the respondents list.
      if (!this.areRespondentSegmentsEqual(matchingDownloadRequestRecord.respondentSegments, request.respondentSegments)) {
        matchingDownloadRequestRecord.respondentSegments = request.respondentSegments;
        matchingDownloadRequestRecord.respondentCompanyCode = request.respondentCompanyCode;
        matchingDownloadRequestRecord.respondentCompanyLabel = request.respondentCompanyLabel;
        this.gridApi.refreshCells();
        // add item to refresh list
        this.addDownloadRequestToUpdateList(matchingDownloadRequestRecord);
      }
      return true;
    }
    // no record was found, so return false so we can add it to the grid.
    return false;
  }

  private areRespondentSegmentsEqual(originalRespondentsList: any[], updateRespondentsList: any[]) {
    if (originalRespondentsList.length !== updateRespondentsList.length) {
      // lengths don't match, so they can't be equal
      return false;
    }
    const sortedArr1 = [...originalRespondentsList].sort((a, b) => a - b); // Clone and sort
    const sortedArr2 = [...updateRespondentsList].sort((a, b) => a - b); // Clone and sort
    return sortedArr1.every((value, index) => value == sortedArr2[index]);
  }

  private resetDropDownsToCachedValues() {
    // Compares all the current dropdowns to what their cached values are.
    // If they do not match, reset it's value to whatever is in the cache.
    if (this.formGroup.controls.period?.getRawValue() !== this.cachedFormValues?.period) {
      this.formGroup.controls.period?.setValue(this.cachedFormValues?.period);
    }
    if (this.formGroup.controls.downloadRequestType?.getRawValue() !== this.cachedFormValues?.downloadRequestType) {
      this.formGroup.controls.downloadRequestType?.setValue(this.cachedFormValues?.downloadRequestType);
    }
    if (this.formGroup.controls.market?.getRawValue() !== this.cachedFormValues?.market) {
      this.formGroup.controls.market?.setValue(this.cachedFormValues?.market);
    }
    if (this.formGroup.controls.industry?.getRawValue() !== this.cachedFormValues?.industry) {
      this.formGroup.controls.industry?.setValue(this.cachedFormValues?.industry);
    }
    if (this.formGroup.controls.surveySubject?.getRawValue() !== this.cachedFormValues?.surveySubject) {
      this.formGroup.controls.surveySubject?.setValue(this.cachedFormValues?.surveySubject);
    }
    if (this.formGroup.controls.locale?.getRawValue() !== this.cachedFormValues?.locale) {
      this.formGroup.controls.locale?.setValue(this.cachedFormValues?.locale);
    }
  }

  /**
   * Resets only the product, sponsor and respondent selection
   * @private
   */
  private resetSelectedDownloadRequestValues() {
    this.products = [];
    // also clear the control as it has stale data in it.
    this.formGroup.controls.products?.reset(null, {emitEvent: false});
    this.resetProductsSponsorsAndRespondents();
  }

  private resetProductsSponsorsAndRespondents() {
    this.selectedProductsAndSponsors.clear();
    this.selectedSponsors = [];
    this.respondentsList = [];
    this.selectedRespondents = [];
    this.createAgCheckBoxTreeWithSelectedValues();
    this.createAgCheckBoxListWithSelectedValues();
  }

  private resetDropDownFilterValues() {
    // I have to call clearValidators and updateValueAndValidity otherwise the Validation.Required will fail
    // when I reset the value to null.
    Util.resetFormControl(this.formGroup.controls.period);
    Util.resetFormControl(this.formGroup.controls.downloadRequestType);
    Util.resetFormControl(this.formGroup.controls.market);
    Util.resetFormControl(this.formGroup.controls.industry);
    Util.resetFormControl(this.formGroup.controls.surveySubject);
    Util.resetFormControl(this.formGroup.controls.locale);

    // Also reset the expedite count and batch code
    this.currentExpeditedCountByMarket = 0;
    this.currentBatchCode = '';
  }

  private createAgCheckBoxListWithSelectedValues() {
    this._checkboxListService.createAgCheckboxList(this.respondentsList, 'respondentSegmentLabel');
  }

  private createAgCheckBoxTreeWithSelectedValues() {
    this._checkboxTreeService.createAgCheckboxTreeFromMap(this.selectedProductsAndSponsors, 'displayValue', 'subjectLabel');
  }

  private setDownloadRequestReportTypeValues(downloadRequest: DownloadRequestData, selectedSponsor: SubjectLabel) {
    const standardReportTypeId = selectedSponsor.standardReportTypeId;
    const matchingReportTypes = this.reportTypes.filter(reportType => reportType.standardReportTypeIds?.includes(standardReportTypeId));
    let matchingReportType: ReportType | undefined;

    // Multiple values returned, possible Full or Presentation selected
    if (matchingReportTypes.length > 1) {
      matchingReportType = matchingReportTypes.find(reportType =>
        reportType.automatedReportName.toLowerCase().includes(this.formGroup.controls.downloadRequestType?.value?.downloadRequestTypeValue.toLowerCase())
      );
    } else {
      matchingReportType = matchingReportTypes[0];
    }
    if (!matchingReportType) {
      // Couldn't find a matching automated report, lets try to find one based on my period and standard report type
      const matchingReportTypesByName = this.reportTypes.filter(reportType =>
        reportType.automatedReportName.toLowerCase().includes(this.formGroup.controls.downloadRequestType?.value.downloadRequestTypeValue.toString().toLowerCase()));
      if (matchingReportTypesByName.length > 1) {
        // more then one matching type returned, lets filter by it's period
        const standardReportType = this.getStandardReportTypeById(standardReportTypeId);
        matchingReportType = matchingReportTypesByName.find(reportType =>
          reportType.versions.includes(standardReportType.period));
      } else {
        matchingReportType = matchingReportTypesByName[0];
      }
    }

    if (!matchingReportType) {
      throw new Error(`Unable to find a matching report type for download request: ${JSON.stringify(downloadRequest)} and the selected sponsor: ${JSON.stringify(selectedSponsor)}`);
    }
    downloadRequest.automatedReportId = matchingReportType.automatedReportId.toString();
    downloadRequest.automatedReportName = matchingReportType.automatedReportName;
    downloadRequest.automatedReportCode = matchingReportType.automatedReportCode;
  }

  private getMatchingDownloadRequestRecordFromDataSource(request: DownloadRequestData) {
    return this.rowData.find(
      selection =>
        this.compareDownloadRequestsForEquality(selection, request)
    );
  }

  private compareDownloadRequestsForEquality(request1: DownloadRequestData, request2: DownloadRequestData) {
    return (
      request1.period === request2.period &&
      request1.marketId === request2.marketId &&
      request1.industryId === request2.industryId &&
      request1.surveySubject === request2.surveySubject &&
      request1.downloadRequestTypeId === request2.downloadRequestTypeId &&
      request1.language === request2.language &&
      request1.productPeriodId === request2.productPeriodId &&
      request1.sponsorId === request2.sponsorId
    )
  }

  private getMaxExpediteDownloadRequestCount(resultSetThresholds: ResultsetThresholds) {
    const maxExpediateThreshold = resultSetThresholds.thresholds.find(result => result.thresholdCode === this.MAX_EXPEDITE_THRESHOLD_CODE);
    if (maxExpediateThreshold) {
      return maxExpediateThreshold.threshold;
    }
    return 0;
  }

  private addDownloadRequestToUpdateList(request: DownloadRequestData) {
    const matchingItem = this.requestsToCheckForDuplicates.find(item => this.compareDownloadRequestsForEquality(item, request));
    if (!matchingItem) {
      this.requestsToCheckForDuplicates.push(request);
    }
  }

  private async getMessageColumnData(params: DownloadRequestData[]) {
    let results: DownloadRequest[] = [];
    if (params && params.length > 0) {
      try {
        results = await this._pptDownloadService.getMatchingDownloadRequests(params);
      } catch (error) {
        this.displayNotificationMessage('error', JSON.stringify(error));
      }
    }
    return results;
  }

  private processMessageColumnResults(results: DownloadRequest[], updatedRequests: DownloadRequestData[]) {
    // Reset properties of the updated requests
    updatedRequests?.forEach(item => {
      const rowItem = this.rowData.find(row => this.compareDownloadRequestsForEquality(row, item));
      if (rowItem) {
        rowItem.message = undefined;
        rowItem.status = undefined;
        rowItem.link = undefined;
      }
    });
    for (const result of results) {
      let rowItem = this.rowData?.find(item =>
        item.productViewId === result.downloadParameters.productviewid &&
        item.productPeriodId === result.downloadParameters.productperiodid &&
        item.weightedProductLabel === result.downloadParameters.product &&
        item.sponsorCode === result.downloadParameters.sponsor);
      if (rowItem) {
        // found a matching rowItem, lets update the status lets check to see if the respondents list is equal
        if (this.areRespondentSegmentsEqual(rowItem.respondentSegments, result.downloadParameters.respondentsegments)) {
          rowItem.message = result.processStatusMessage;
          rowItem.status = result.processStatus;
          rowItem.link = result.sharepointFolderUrl;
        }
      }
    }
    this.gridApi?.refreshCells({
      force: true,
      columns: ["status", "message", "link"]
    });
  }

  private async getBatchCode(value: Partial<DownloadRequestTemplate>) {
    const result = await this._downloadRequestService.getNextBatchCode(value?.surveySubject ?? '',
      value?.market?.marketCode ?? '');
    return result.value;
  }

  /**
   * Validates the download request passed in.  All values (period, market, etc) must match otherwise it's invalid
   * @param downloadRequestsToRetry
   * @private
   */
  private validateDownloadRequestsToRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    const mainDownloadRequestParameters = JSON.parse(downloadRequestsToRetry[0].downloadParameters ?? '');
    return downloadRequestsToRetry.every(request => {
        const downloadRequestParameter = JSON.parse(request.downloadParameters ?? '');
        return request.country === downloadRequestsToRetry[0].country
          && request.period === downloadRequestsToRetry[0].period
          && downloadRequestParameter.dashboardprefix === mainDownloadRequestParameters.dashboardprefix
          && downloadRequestParameter.survey_subject === mainDownloadRequestParameters.survey_subject
          && downloadRequestParameter.report_type === mainDownloadRequestParameters.report_type
          && downloadRequestParameter.language === mainDownloadRequestParameters.language
      }
    );
  }

  private async processDownloadRequestsToRetry(downloadRequestsToRetry: ViewDownloadRequest[] | undefined) {
    if (downloadRequestsToRetry) {
      // Populate the drop downs, and build the product and sponsors tree.  We await this, to ensure that the processing is finalized
      // before moving on to trying to pre-select the items
      await this.populateAndDisableFilterDropdownsOnRetry(downloadRequestsToRetry);
      // Pre-select the product and sponsors.  Again, we await this so that we can properly select the respondents from the list
      await this.updateProductAndSponsorsTreeOnRetry(downloadRequestsToRetry);
      // Pre-select the respondents, we don't need to await as it's the last part of the chain
      this.updateRespondentsCheckListOnRetry(downloadRequestsToRetry);
    }
  }

  private async populateAndDisableFilterDropdownsOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Manually set the form controls and drop downs.  It does not currently trigger handleValueChanges event, so we need to do some processing
    // from that method manually.
    const request = downloadRequestsToRetry[0];
    // Set the original batch code.
    this.originalBatchCodeFromRetry = request.batchCode;
    const downloadRequestParameters = JSON.parse(request.downloadParameters ?? '');
    this.setFormControlValueAndDisable(this.formGroup.controls.period, request.period);
    const market = this.markets.find(m => m.marketCode === request.country);
    this.setFormControlValueAndDisable(this.formGroup.controls.market, market);
    this.setFormControlValueAndDisable(this.formGroup.controls.surveySubject, downloadRequestParameters.survey_subject);
    const downloadRequestType = this.downloadRequestTypes.find(t => t.downloadRequestTypeValue === downloadRequestParameters.report_type);
    this.setFormControlValueAndDisable(this.formGroup.controls.downloadRequestType, downloadRequestType);
    this.setFormControlValueAndDisable(this.formGroup.controls.locale, downloadRequestParameters.language);

    // get batch code here, since the events for handling value changes do not fire
    this.currentBatchCode = await this.getBatchCode(this.formGroup.getRawValue());

    // Get the list of products we need to select
    let productPeriodIdList: number[] = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      productPeriodIdList.push(Number(requestParams.productperiodid));
    }

    // make the web service to attempt to determine which industry is being used.  This currently acts as a 'best guess'
    // and may not be 100% accurate in all scenarios.
    await this._pptDownloadService.getIndustryFromProductPeriodIds(productPeriodIdList).then(result => {
      const industry = this.industries.find(i => i.industryId === result.value);
      this.setFormControlValueAndDisable(this.formGroup.controls.industry, industry);
    });

    // Manually get the products.
    await this.getWeightedProductLabels();
    let productLabels: WeightedProductLabel[] = this.products.filter(p => productPeriodIdList.includes(p.productPeriodId));
    // manually call handleProductChange with our list of pre-selected items;
    await this.handleProductChanged(productLabels);
    this.disableProductDropdown = true;
  }

  private setFormControlValueAndDisable(control: FormControl<any> | undefined, value: any, sendEvent: boolean = false) {
    if (control) {
      control.setValue(value, {emitEvent: sendEvent});
      control.disable({onlySelf: true});
    }
  }

  private async updateProductAndSponsorsTreeOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // used the TreeSelectChange object to create the mapping.  This can be replaced with a proper map, I just thought it would be easier this way
    let itemsToSelectMap: TreeSelectionChanged[] = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      const productPeriodId = Number(requestParams.productperiodid);
      const productViewId = Number(requestParams.productviewid);
      const sponsorCode = downloadRequest.sponsor;
      for (const [product, subject] of this.selectedProductsAndSponsors) {
        if (product.productPeriodId === productPeriodId && product.productViewId === productViewId) {
          let treeSelectionChangedEvent = itemsToSelectMap.find(item => item.parentItem.productPeriodId === productPeriodId && item.parentItem.productViewId === productViewId);
          if (!treeSelectionChangedEvent) {
            treeSelectionChangedEvent = {
              parentItem: undefined,
              changeType: SelectionChangeType.ADD,
              childrenItems: []
            }
            itemsToSelectMap.push(treeSelectionChangedEvent);
          }
          treeSelectionChangedEvent.parentItem = product;
          const matchingSubject = subject.find(s => s.subjectCode === sponsorCode);
          if (matchingSubject) {
            treeSelectionChangedEvent.childrenItems.push(matchingSubject);
          }
        }
      }
    }
    for (const item of itemsToSelectMap) {
      this._checkboxTreeService.setSelectedItems(item.parentItem, item.childrenItems, 'displayValue', 'sponsorCode', false);
      // Manually trigger the selection change, so we can await this functionality.  This allow us to ensure that the respondents list does not
      // get updated before this is process is done.  Without this, the UI will be rendering as we try to pre-select the respondents.
      await this.onCheckBoxTreeSelectionChanged(item);
    }
  }

  private updateRespondentsCheckListOnRetry(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Gets a list of all the respondents Ids from the download requests.
    let respondentsToSelect = [];
    for (let downloadRequest of downloadRequestsToRetry) {
      const requestParams = JSON.parse(downloadRequest.downloadParameters ?? '');
      const respondents = requestParams.respondentsegments;
      respondentsToSelect.push(...respondents);
    }
    // Create a distinct list of respondent Ids that are returned and convert it to a number
    let distinctRespondents = Array.from(new Set(respondentsToSelect.map(r => Number(r))));
    const respondentLabels = this.respondentsList.filter(r => distinctRespondents.some(id => r.respondentSegmentId === id));
    this._checkboxListService.setSelectedItems(respondentLabels, 'respondentSegmentId', true);
  }
}
