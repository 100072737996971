import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {ColumnFilterType, TransferRunStatus} from "@data/enums/data.enums";
import {StatusColumnComponent} from "@pages/segments/grid/columns/status-column/status-column.component";
import {Util} from "@data/util/util";
import {DurationColumnComponent} from "@pages/segments/grid/columns/duration-column/duration-column.component";
import {ActionColumnComponent} from "@pages/segments/grid/columns/action-column/action-column.component";
import {MultiLineLinkColumnComponent} from "@pages/segments/grid/columns/multi-line-link-column/multi-line-link-column.component";
import {SelectColumnComponent} from "@pages/segments/grid/columns/select-column/select-column.component";

export const stopClickAction: string = 'stop_button_link_click';
export const taskIdClickAction: string = 'task_id_link_click';

export let pageContext: {
  [key: string]: boolean | string | undefined
  st_ip: boolean;
  st_p: boolean;
  st_c: boolean;
  st_cs: boolean;
  st_ce: boolean;
  st_to: boolean;
  sd_r: string | undefined;
  ed_r: string | undefined;
  c_f: string | undefined;
  kw_f: string | undefined;
  d_s: string | undefined;
  d_e: string | undefined;
} = {
  st_ip: false,
  st_p: false,
  st_c: false,
  st_cs: false,
  st_ce: false,
  st_to: false,
  sd_r: undefined,
  ed_r: undefined,
  c_f: undefined,
  kw_f: undefined,
  d_s: undefined,
  d_e: undefined,
};

export let tableColumns: (GridColumn | GridColumnGroupDef) [] = [
  {
    field: 'select',
    headerName: '',
    hide: false,
    isMandatory: false,
    downloadable: false,
    filter: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    pinned: "left",
    cellRenderer: SelectColumnComponent,
    maxWidth: 75
  },

  {
    field: 'taskIdentifier',
    headerName: 'Task Id',
    hide: false,
    isMandatory: false,
    autoHeight: true,
    filter: ColumnFilterType.TEXT,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: MultiLineLinkColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        subText: params.data.taskType,
        actionEvent: taskIdClickAction
      };
    }
  },

  {
    field: 'taskStatus',
    headerName: 'Status',
    hide: false,
    isMandatory: false,
    filterId: 's_f',
    filter: ColumnFilterType.DROPDOWN,
    filterParams: {defaultToNothingSelected: true},
    cellRenderer: StatusColumnComponent
  },

  {
    field: 'startDatetime',
    headerName: 'Start Datetime',
    hide: false,
    isMandatory: false,
    filterId: 'sd_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }
  },

  {
    field: 'endDatetime',
    headerName: 'End Datetime',
    hide: false,
    isMandatory: false,
    filterId: 'ed_r',
    filter: ColumnFilterType.DATE,
    filterParams: {defaultOption: 'inRange', maxNumConditions: 1, comparator: Util.dateComparator},
    valueFormatter: (params) => {
      return Util.getFormattedDate(params.value);
    }

  },

  {
    field: 'duration',
    headerName: 'Duration',
    hide: false,
    isMandatory: false,
    filter: ColumnFilterType.NUMBER,
    filterId: 'd_s',
    filterParams: {
      filterOptions: ['greaterThanOrEqual', 'lessThanOrEqual', 'inRange'],
      inRangeInclusive: true,
      defaultOption: 'inRange',
      maxNumConditions: 1,
      //allow only number and colon,
      //this pattern doesn't validate target string but only input chars one by one so we can't add something like: \d{2}:[0-50-9]{2}:[0-50-9]{2}
      allowedCharPattern: '\\d:',
      buttons: ['reset'],
      filterPlaceholder: 'hh:mm:ss',
      numberParser: (text: string) => {
        return text == null ? null : Util.parseDuration(text);
      },
      numberFormatter: (value: number) => {
        return value == null ? null : Util.formatDuration(value);
      }
    },
    cellRenderer: DurationColumnComponent,
  },

  {
    field: 'transferCode',
    headerName: 'Transfer Code',
    hide: true,
    isMandatory: false
  },

  {
    field: 'runCode',
    headerName: 'Run Code',
    hide: true,
    isMandatory: false
  },

  {
    field: 'action',
    headerName: 'Action',
    hide: false,
    isMandatory: true,
    downloadable: false,
    filter: false,
    cellRenderer: ActionColumnComponent,
    cellRendererParams: function (params: any) {
      return {
        actions: [
          {
            toolTip: "Stop",
            icon: "fa-stop",
            name: "Stop transfer",
            disabled: params.data.transferState != TransferRunStatus.IN_PROGRESS,
            actionEvent: stopClickAction
          },
          {
            toolTip: "log entries",
            icon: "fa-rectangle-vertical-history",
            name: "Log Entries",
            routerLink: ['/system', 'transfers', 'run-history', params.data.transferId, 'messages']
          }

        ]
      };
    }
  }
]
