import {Component, TemplateRef, ViewChild} from '@angular/core';
import {TableBaseComponent} from "@pages/table.base.component";
import {DownloadRequestMetrics, ViewDownloadRequest,} from "@data/interefaces/download.request.interfaces";
import {DownloadRequestService} from "@data/download-request/download.request.service";
import {Util} from "src/app/data/util/util";
import {DownloadRequestUtil} from "@data/download-request/download.request.util";
import {
  cancelClickAction,
  expediteClickAction,
  pageContext,
  retryClickAction,
  setCurrentUser,
  setDownloadRequestThreshold,
  setIsUserAdmin,
  setMarketCodesToLastExpeditedMap,
  tableColumns
} from "@pages/download-request/download-request-list.component.ds";
import {FilterChangedEvent, SelectionChangedEvent, ViewportChangedEvent} from "ag-grid-community";
import {ActivatedRoute} from "@angular/router";
import {AgGridService} from "@shared/services/ag-grid.service";
import {ResultsetThresholds} from "@data/interefaces/data.interfaces";
import {DownloadRequestProcessStatus, NotificationType} from "@data/enums/data.enums";
import {KpiSidebarItem} from "@shared/ag-kpi-sidebar/ag-kpi-sidebar.component.ds";


@Component({
  selector: 'app-download-request',
  templateUrl: './download-request-list.component.html',
  styleUrls: ['./download-request-list.component.scss'],
  providers: [AgGridService]
})
export class DownloadRequestListComponent extends TableBaseComponent {

  auxLinks: Map<string, string>;
  rowData: ViewDownloadRequest[] = [];
  refreshComplete: boolean = true;
  isFilterActive: boolean = false;
  selectedRows: ViewDownloadRequest[] = [];
  kpiSidebar: KpiSidebarItem[] = [];
  @ViewChild('footerActionButtonPanel') footerActionButtonPanel: TemplateRef<any> | undefined;
  canBulkCancel: boolean = false;
  canBulkRetry: boolean = false;
  protected downloadRequestUtil = DownloadRequestUtil;
  protected readonly pageContext = pageContext;
  protected override readonly tableColumns = tableColumns;
  protected readonly util = Util;
  private readonly MAX_EXPEDITE_THRESHOLD_CODE: string = 'max-expedite-count';
  private maxExpediteDownloadRequestsCount: number = 0;
  private marketCodesToLastExpeditedCount: Map<String, number> = new Map();
  private readonly DEFAULT_PRIORITY: number = 5;
  private readonly EXPEDITED_PRIORITY: number = 1;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _downloadRequestService: DownloadRequestService) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  public override ngOnInit() {
    super.ngOnInit();
    this.initGridOptions();
    this.initiatePageState(this._activatedRoute.snapshot.queryParamMap);
    this.updatePageContext();
    this.refreshView();
    // we cannot call refreshView() in ngAfterViewInit(), we will get
    // NG0100: Expression has changed after it was checked error

    this.displayProgressBar(true);
  }

  onRowSelectionChanged(event: SelectionChangedEvent) {
    this.refreshGridHeaderOnSelectionChanged(event);
    this.selectedRows = this.getSelectedRows();
    this.canBulkCancel = this.selectedRows.length != 0 && (this.selectedRows.every(item => (this._store.isAdmin || item.requestedBy === this._store.email)
      && item.processStatus === DownloadRequestProcessStatus.PENDING));
    // Must be a status other than pending and in progress.  And the batch codes must be the same.
    this.canBulkRetry = this.selectedRows.length != 0 && (this.selectedRows.every(item =>
      (item.processStatus !== DownloadRequestProcessStatus.PENDING && item.processStatus !== DownloadRequestProcessStatus.IN_PROGRESS)
      && item.batchCode === this.selectedRows[0].batchCode
      && Util.isValidDownloadRequestBatchCode(item.batchCode)));
  }

  onLiveDataFilterChange(forceReload: boolean = false): void {
    if (forceReload) {
      this.refreshView();
    }
    this.updateToPageContextUrl();
  }

  async refreshView() {
    await this.loadPageData().catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
  }

  async refreshLastExpeditedByMarketCodes(): Promise<void> {
    let result = await this._downloadRequestService.getLastExpeditedByMarketCodes();
    result.listElements?.forEach(dataItem => {
      this.marketCodesToLastExpeditedCount.set(dataItem.key, dataItem.value);
      setMarketCodesToLastExpeditedMap(this.marketCodesToLastExpeditedCount);
    });
  }

  async loadPageData() {
    this.displayProgressBar(true);
    // Sets the admin user status in the ds component.
    setCurrentUser(this._store.email);
    setIsUserAdmin(this._store.isAdmin);
    this.refreshComplete = false;

    let promises: Promise<any>[] = []
    promises.push(this.refreshLastExpeditedByMarketCodes());
    promises.push(this._downloadRequestService.getDownloadRequestThresholds().then(result => {
      this.maxExpediteDownloadRequestsCount = this.getMaxExpediteDownloadRequestCount(result);
      setDownloadRequestThreshold(this.maxExpediteDownloadRequestsCount);
    }));
    await Promise.all(promises);

    this._downloadRequestService.getAllDownloadRequests().then(result => {
      result.sort((a, b) => new Date(b.requestDate!).getTime() - new Date(a.requestDate!).getTime());
      this.rowData = result.map((element) => this.downloadRequestUtil.mapDownloadRequestToView(element));
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error))
    ).finally(() => {
      this.refreshComplete = true;
      this.displayProgressBar(false)
    });
  }

  onFilterChanged(event: FilterChangedEvent<any>) {
    const updateLiveDataFilter = this.isUpdateLiveDataFilterRequired(event.columns);
    this._agGridService.processOnFilterChanged(event, this.pageContext, this.updatePageContext.bind(this)).then(() => {
      if (updateLiveDataFilter) {
        this.onLiveDataFilterChange(true);
      }
    });
  }

  onCancelButtonClick() {
    if (this.canBulkCancel) {
      const ids = this.selectedRows.map(item => item.id);
      if (ids) {
        this.callCancelRequest(ids);
      }
    }
  }

  onRetryButtonClick() {
    if (this.canBulkRetry) {
      this.navigateToRetryPage(this.selectedRows);
    }
  }

  onPaginationChanged(event: any) {
    const currentPage = event.api.paginationGetCurrentPage();
    const pageSize = event.api.paginationGetPageSize();
    const startRow = currentPage * pageSize;
    const endRow = Math.min(startRow + pageSize, this.rowData.length);
    const ids = this.getRowsAfterFilter().slice(startRow, endRow).filter(row => !!row).map(item => item.id);
    if (ids?.length > 0) {
      this._downloadRequestService.getDownloadMetrics(ids)
        .then(result => {
          const metricsMap = new Map<number, DownloadRequestMetrics>();
          result.forEach(metric => {
            if (metric.id !== undefined) {
              metricsMap.set(metric.id, metric);
            }
          });
          // Iterate through the rowData array and copy metrics
          this.getRowsAfterFilter().slice(startRow, endRow).filter(row => !!row).forEach(request => {
            if (request.id !== undefined && metricsMap.get(request.id)) {
              request.metrics = metricsMap.get(request.id);
            }
          });
          this.gridApi.refreshCells({
            force: true
          });
        })
        .catch(error => this.displayNotificationMessage('error', JSON.stringify(error)));
    }
  }

  protected override getTableFooterActionButtonPanel(): TemplateRef<any> | undefined {
    return this.footerActionButtonPanel;
  }

  protected override initAfterGridReady() {
    super.initAfterGridReady();
    this.gridApi.addEventListener(expediteClickAction, (event: any) => {
      this.onExpediteClick(event.detail.rowData);
    });
    this.gridApi.addEventListener(cancelClickAction, (event: any) => {
      this.onCancelActionButtonClick(event.detail.rowData);
    });
    this.gridApi.addEventListener(retryClickAction, (event: any) => {
      this.onRetryActionButtonClick(event.detail.rowData);
    })
  }

  protected updatePageContext(updateContextUrl: boolean = true): void {
    this.isFilterActive = Util.checkFilterActive(this.pageContext);
    if (updateContextUrl) {
      this.updateToPageContextUrl();
    }
    this.populateKpiMetrics();
  }

  protected override onViewportChanged(event: ViewportChangedEvent<any>) {
    this.populateKpiMetrics();
  }

  private navigateToRetryPage(downloadRequestsToRetry: ViewDownloadRequest[]) {
    // Add the records to the routers built-in state mechanism.  When the parent of the component loads
    // (in our case, the Task component) we can grab the values from the routers state there and pass them
    // in as a input the add page.
    this._router.navigate(['/data-management', 'download', 'download-request-queue', 'task', 'add'], {
      state: {requestsToRetry: downloadRequestsToRetry}
    });
  }

  private initGridOptions() {
    const options = this._agGridService.getDefaultAgiGridOptions()
    // options.paginateChildRows = true; // Add this option to ensure that when the group is expanded, the children become paginated
    options.groupDefaultExpanded = 1; // All groups will startexpanded
    // group column defintion options
    options.autoGroupColumnDef = {
      headerName: 'Batch Codes',
      field: 'batchCode',
      cellRenderer: 'agGroupCellRenderer',
      filter: false,
      cellRendererParams: {
        suppressCount: true, // Show count of rows in the group
        checkbox: false,
      },
      minWidth: 225,
      valueFormatter: params => '' // return empty string to ensure the autoGroupColumn value is an empty column
    };
    options.groupSelectsChildren = true;
    this.baseAgGridOptions = options;
  }

  private async callCancelRequest(ids: (number | undefined)[]) {
    const dialogResult = await this.displayDialogMessage(NotificationType.INFO, "Are you sure you want to cancel the selected download requests?");
    if (dialogResult) {
      this.displayProgressBar(true);
      this._downloadRequestService.cancelDownloadRequests(ids).then(result => {
        if (result.status) {
          this.displayNotificationMessage("success", "Requests successfully cancelled");
        } else {
          this.displayNotificationMessage("warning", "Not all requests were cancelled.  Check Alert Messages column for more details.");
        }
      }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
        .finally(async () => {
          this.displayProgressBar(false);
          await this.refreshView();
        });
    }
  }

  private onCancelActionButtonClick(downloadRequest: ViewDownloadRequest) {
    if (!this._store.isAdmin && downloadRequest.requestedBy !== this._store.email) {
      this.displayNotificationMessage('error', "Cannot cancel requests you do not own.");
      return;
    }
    const id = downloadRequest.id;
    if (!id) {
      this.displayNotificationMessage('error', `Invalid download request id.`);
      return;
    }
    this.callCancelRequest([id]);
  }

  private onExpediteClick(downloadRequest: ViewDownloadRequest) {
    const id = downloadRequest.id;
    if (!id) {
      this.displayNotificationMessage('error', `Invalid download request id.`);
      return;
    }

    this.displayProgressBar(true);
    const isExpedite = downloadRequest.priority != this.EXPEDITED_PRIORITY;
    const serviceCall = isExpedite
      ? this._downloadRequestService.expediteDownloadRequest(id)
      : this._downloadRequestService.downgradeDownloadRequest(id);

    const updatedPriorityValue = isExpedite ? this.EXPEDITED_PRIORITY : this.DEFAULT_PRIORITY;
    const successMessage = isExpedite
      ? 'Download request successfully expedited.'
      : 'Download request successfully downgraded.';

    serviceCall.then(result => {
      if (result.status) {
        downloadRequest.priority = updatedPriorityValue;
        this.gridApi.refreshCells({
          force: true,
          columns: ["priority", "action"]
        });
        this.displayNotificationMessage('success', successMessage);
      }
    }).catch(error => this.displayNotificationMessage('error', JSON.stringify(error)))
      .finally(() => this.displayProgressBar(false));
  }

  private onRetryActionButtonClick(downloadRequest: ViewDownloadRequest) {
    this.navigateToRetryPage([downloadRequest]);
  }

  private getMaxExpediteDownloadRequestCount(resultSetThresholds: ResultsetThresholds) {
    const maxExpediteThreshold = resultSetThresholds.thresholds.find(result => result.thresholdCode === this.MAX_EXPEDITE_THRESHOLD_CODE);
    if (maxExpediteThreshold) {
      return maxExpediteThreshold.threshold;
    }
    return 0;
  }

  private populateKpiMetrics() {
    let filteredRows = this.getRowsAfterFilter().filter(row => !!row);
    let {
      pendingRequests,
      inProgressRequests,
      availableRequests,
      expediteRequests
    } = this.calculateKpiMetrics(filteredRows);
    this.kpiSidebar = [
      {
        title: "Pending",
        value: pendingRequests
      },
      {
        title: "In Progress",
        value: inProgressRequests
      },
      {
        title: "Available",
        value: availableRequests
      },
      {
        title: "Expedite",
        value: expediteRequests
      }
    ];
  }

  private calculateKpiMetrics(rows: ViewDownloadRequest[]) {
    let pendingRequests = rows.filter(row => row.processStatus === DownloadRequestProcessStatus.PENDING).length;
    let inProgressRequests = rows.filter(row => row.processStatus === DownloadRequestProcessStatus.IN_PROGRESS).length;
    let availableRequests = rows.filter(row => row.processStatus === DownloadRequestProcessStatus.READY_FOR_DOWNLOAD).length;
    let expediteRequests = rows.filter(row =>
      (row.processStatus === DownloadRequestProcessStatus.PENDING || row.processStatus === DownloadRequestProcessStatus.IN_PROGRESS) && row.expeditedBy).length;
    return {pendingRequests, inProgressRequests, availableRequests, expediteRequests};
  }
}
