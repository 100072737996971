import {Injectable} from '@angular/core';
import {ApplicationStatus, SurveyCleanupApplicationSettings} from "../../interefaces/data.interfaces";
import {ApiService} from "src/app/core/api/api.service";

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(private _apiService: ApiService) {
  }

  getApplicationStatus() {
    return this._apiService.makeGetServiceCall<ApplicationStatus[]>('/v2/api/heartbeat');
  }

  getSurveyCleanupApplicationSettings() {
    return this._apiService.makeGetServiceCall<SurveyCleanupApplicationSettings>('/v2/api/heartbeat/application-settings/survey-cleanup');
  }
}
