import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {ActivatedRoute, Router} from "@angular/router";
import {UniqueLinkInvitee} from "@data/interefaces/panel.interfaces";
import {displayTableColumns} from "@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component.ds";
import {ViewDownloadRequest} from "@data/interefaces/download.request.interfaces";
import {FeatureComponentMode} from "@core/enums/core.enums";
import {Features} from "@core/constants/core.constants";

@Component({
  selector: 'download-request-task',
  templateUrl: './download-request-task.component.html',
  styleUrls: ['./download-request-task.component.scss']
})
export class DownloadRequestTaskComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Create New Download Request",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_ROW
    },
    {
      title: "Create New Parameter Request",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_LEGACY,
      feature: {feature: Features.DOWNLOAD_REQUEST.TYPE, featureFlag: Features.DOWNLOAD_REQUEST.LEGACY_DOWNLOAD_REQUEST}
    },
    {
      title: "Details",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.DETAIL
    }
  ];

  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: UniqueLinkInvitee[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = '';
  downloadRequestsToRetry?: ViewDownloadRequest[];

  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = displayTableColumns;
  protected readonly FEATURES = Features;

  constructor(protected _activatedRoute: ActivatedRoute, private _route: Router) {
    // A hard refresh (f5) in the browser, will keep the state in memory.
    this.downloadRequestsToRetry = (this._route.getCurrentNavigation()?.extras.state as { requestsToRetry?: any })?.requestsToRetry || undefined
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }
}
