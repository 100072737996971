import {Component, Input} from '@angular/core';

@Component({
  selector: 'ag-status-chip',
  templateUrl: './ag-status-chip.component.html',
  styleUrls: ['./ag-status-chip.component.scss']
})
export class AgStatusChipComponent {

  @Input() status: string | undefined;

  private readonly STATUS_INFO: string[] = [
    "info",
    "not scheduled",
    "ready for approval",
    "not started",
    "pending",
    "cancelled",
    "single",
    "sponsor",
    "agi",
    "aborted"
  ];
  private readonly STATUS_SUCCESS: string[] = [
    "success",
    "completed",
    "completed successfully",
    "approved",
    "complete",
    "retailer",
    "active",
    "ready for download",
    "yes",
    "y",
    "rollup",
    "custom sponsor",
    "ascribe",
    "up",
    "available"
  ];
  private readonly STATUS_WARN: string[] = [
    "warning",
    "stopped",
    "in progress",
    "inprogress",
    "under construction",
    "supplier",
    "industry",
    "confirmit"
  ];
  private readonly STATUS_ERROR: string[] = [
    "error",
    "completed with errors",
    "invalid",
    "failed",
    "no",
    "failed with system error",
    "failed with infrastructure error",
    "failed with validation error",
    "custom industry",
    "msexcel",
    "down"
  ];
  private readonly STATUS_NONE: string[] = [
    ' '
  ];

  getStatusColour() {
    let tmpStatus: string = this.status?.toLocaleLowerCase() || '';
    if (this.STATUS_INFO.includes(tmpStatus)) {
      return "status-info";
    } else if (this.STATUS_WARN.includes(tmpStatus)) {
      return "status-warn";
    } else if (this.STATUS_ERROR.includes(tmpStatus)) {
      return "status-error";
    } else if (this.STATUS_SUCCESS.includes(tmpStatus)) {
      return "status-success";
    } else if (this.STATUS_NONE.includes(tmpStatus)) {
      return "status-none";
    } else if (tmpStatus) {
      return "status";
    } else {
      return "status-none";
    }
  }
}
