import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";

import {FeatureComponentMode} from "@core/enums/core.enums";

export class TableColumnsUtil {

  public static setColumns(tableColumns: (GridColumn | GridColumnGroupDef)[], featureComponentMode: FeatureComponentMode) {
    tableColumns.forEach(column => {
      if ((column as GridColumnGroupDef).children) {
        this.setColumnGroup(column as GridColumnGroupDef, featureComponentMode);
      } else {
        this.setColumn(column as GridColumn, featureComponentMode);
      }
    });
  }

  protected static setColumnGroup(columnGroup: GridColumnGroupDef, featureComponentMode: FeatureComponentMode) {
    switch (columnGroup.groupId) {
      case '_data_filter':
        columnGroup.headerName = featureComponentMode === FeatureComponentMode.DEFAULT ? 'Data Filter' : '-';
        break;
      default:
        // leave as is
        break;
    }
    // recurse into columns
    if (featureComponentMode !== FeatureComponentMode.DEFAULT && columnGroup.headerName === 'Data Filter') {
      columnGroup.headerName = '-';
    } else if (featureComponentMode === FeatureComponentMode.DEFAULT && columnGroup.headerName === '-') {
      columnGroup.headerName = 'Data Filter';
    }
    columnGroup.children.forEach(column => {
      if ((column as GridColumnGroupDef).children) {
        this.setColumnGroup(column as GridColumnGroupDef, featureComponentMode);
      } else {
        this.setColumn(column as GridColumn, featureComponentMode);
      }
    });
  }

  protected static setColumn(column: GridColumn, featureComponentMode: FeatureComponentMode): void {
    throw new Error("setColumn method must be implemented by subclasses");
  }


}

