import {Injectable} from '@angular/core';
import {ApiService} from "@core/api/api.service";
import {SurveyProject, Surveys, ViewIndustry, ViewMarket} from "@data/interefaces/reference.interfaces";

@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(private _apiService: ApiService) {
  }

  public getSurveyById(surveyId: number): Promise<Surveys> {
    return this._apiService.makeGetServiceCall<Surveys>(`/v2/api/reference/surveys/${surveyId}`);
  }

  public getAllSurveyProjects(): Promise<SurveyProject[]> {
    return this._apiService.makeGetServiceCall<SurveyProject[]>(`/v2/api/reference/survey-projects`);
  }

  public getAllMarkets(): Promise<ViewMarket[]> {
    return this._apiService.makeGetServiceCall<ViewMarket[]>(`/v2/api/reference/markets`);
  }

  public getAllIndustries(): Promise<ViewIndustry[]> {
    return this._apiService.makeGetServiceCall<ViewIndustry[]>(`/v2/api/reference/industries`);
  }

}
