import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {ActivatedRoute} from "@angular/router";

import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-event-type-task',
  templateUrl: './event-type-task.component.html',
  styleUrls: ['./event-type-task.component.scss']
})
export class EventTypeTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Edit Row",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.EDIT_ROW
    },
  ];
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  isUrlMode: boolean = true;
  urlModeGoBackUrl: string = "/system/events/event-types";

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }

  protected readonly FeatureComponentMode = FeatureComponentMode;
}
