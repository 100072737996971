import {AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef} from '@angular/core';
import {AgClassifierDirective} from "@shared/ag.classifier.directive";
import {NavigationService} from "@core/api/navigation.service";
import {Router} from "@angular/router";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {FeatureComponentMode} from "@core/enums/core.enums";
import {FeatureConfig} from "@core/interfaces/data.interfaces";
import {FeatureService} from "@core/feature/feature.service";

@Component({
  selector: 'ag-task',
  templateUrl: './ag-task.component.html',
  styleUrls: ['./ag-task.component.scss']
})
export class AgTaskComponent implements AfterContentInit, OnInit {

  @Input() subtitleSegments: string[] | undefined;
  @Input() sidebarEl: TaskSidebarItem[] = [];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() isUrlMode!: boolean;
  @Input() urlModeGoBackUrl!: string;
  @Input() goBackTitle: string = 'Back';
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  currentTemplateOutlet: TemplateRef<any> | null = null;
  @ContentChildren(AgClassifierDirective) outletList: QueryList<AgClassifierDirective> | null = null;
  filteredSideBarItems: TaskSidebarItem[] = [];

  constructor(private _navigation: NavigationService, private _router: Router, private _featureService: FeatureService) {
  }

  async ngOnInit() {
    this.filteredSideBarItems = await this.filterSideBarItems(this.sidebarEl);
  }

  ngAfterContentInit(): void {
    this.selectCurrentTaskAndUnselectOthers()
  }

  goBack() {
    if (this.isUrlMode) {
      this._navigation.back(this.urlModeGoBackUrl, ["task"]);
    } else {
      this.goBackEvent.emit();
    }
  }

  taskSidebarItemClicked(task: TaskSidebarItem) {
    if (!task.disabled) {
      if (this.isUrlMode) {
        this._router.navigate([this.getSelectedTaskPath(task)]).then(r => {
          this.selectCurrentTaskAndUnselectOthers(task);
        });
      } else {
        this.selectCurrentTaskAndUnselectOthers(task);
      }
    }
  }

  async checkForFeature(feature?: FeatureConfig) {
    let isEnabled = true;
    if (feature) {
      isEnabled = await this._featureService.isFeatureEnabled(feature.feature, feature.featureFlag);
    }
    return isEnabled;
  }

  private selectCurrentTaskAndUnselectOthers(task?: any) {
    let isTaskProvided = task !== undefined;
    this.sidebarEl.forEach(element => {
      if (this.selectedFeatureMode && (isTaskProvided && element === task || !isTaskProvided && element.mode === this.selectedFeatureMode)) {
        element.selected = true;
        this.switchSelectedTaskTemplate(element);
      } else {
        element.selected = false;
      }
    });
  }

  private getSelectedTaskPath(task: TaskSidebarItem) {
    const pathArray = this._router.url.split('/');
    pathArray.pop();
    const resultString = pathArray.join('/');
    return resultString + "/" + task.mode;
  }

  private switchSelectedTaskTemplate(task: TaskSidebarItem) {
    this.outletList?.forEach(template => {
      if (template.id == task.mode) {
        this.currentTemplateOutlet = template.template;
      }
    })
  }

  private async filterSideBarItems(sidebarEl: TaskSidebarItem[]) {
    if (sidebarEl) {
      const filteredItems: TaskSidebarItem[] = [];
      for (const item of sidebarEl) {
        if (item.feature) {
          const isEnabled = await this._featureService.isFeatureEnabled(item.feature.feature, item.feature.featureFlag);
          if (isEnabled) {
            filteredItems.push(item);
          }
        } else {
          filteredItems.push(item);
        }
      }
      return filteredItems;
    }
    return sidebarEl;
  }
}
