import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {ProductRespondentCompany} from "@data/interefaces/data.interfaces";
import {displayTableColumns} from "@pages/panel/unique-link-invitees/unique-link-invitees-bulk/unique-link-invitees-bulk.component.ds";
import {ActivatedRoute} from "@angular/router";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-respondent-company-task',
  templateUrl: './respondent-company-task.component.html',
  styleUrls: ['./respondent-company-task.component.scss']
})
export class RespondentCompanyTaskComponent extends BaseComponent {
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: ProductRespondentCompany[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Edit Row",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.EDIT_ROW
    },
    {
      title: "Bulk Include",
      icon: "fa-arrow-right-to-arc",
      selected: false,
      urlSupport: false,
      disabled: false,
      mode: FeatureComponentMode.BULK_INCLUDE
    },
    {
      title: "Upload",
      icon: "fa-upload",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    }
  ];
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/forms/product-spec/respondent-company";
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = displayTableColumns;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }
}
