import {Component, TemplateRef} from '@angular/core';
import {GridApi, IStatusPanelParams} from "ag-grid-enterprise";
import {IStatusPanelAngularComp} from "ag-grid-angular";

@Component({
  selector: 'ag-table-footer',
  templateUrl: './ag-table-footer.component.html',
  styleUrls: ['./ag-table-footer.component.scss']
})
export class AgTableFooterComponent implements IStatusPanelAngularComp {
  currentPage: number = 0;
  pageSize: number = 0;
  totalPages: number = 0;
  rowCount: number = 0;
  actionButtonPanelDef: TemplateRef<any> | null = null;
  pageSizeOptions: number[] = [15, 25, 50, 100];
  private agApi!: GridApi

  refresh(params: IStatusPanelParams<any, any>): boolean {
    return true;
  }

  agInit(params: IStatusPanelParams<any, any>): void {
    this.agApi = params.api;
    this.actionButtonPanelDef = (params as any).actionPanelDef;
    this.agApi.addEventListener("paginationChanged", (params: IStatusPanelParams) => {
      this.currentPage = this.agApi.paginationGetCurrentPage();
      this.pageSize = this.agApi.paginationGetPageSize();
      this.totalPages = this.agApi.paginationGetTotalPages();
      this.rowCount = this.agApi.paginationGetRowCount();
    });
  }

  goToFistPage() {
    this.agApi.paginationGoToFirstPage();
  }

  toToPreviousPage() {
    this.agApi.paginationGoToPreviousPage();
  }

  toToNextPage() {
    this.agApi.paginationGoToNextPage();
  }

  goToLastPage() {
    this.agApi.paginationGoToLastPage();
  }

  onPageSizeChange() {
    this.agApi.setGridOption('paginationPageSize', this.pageSize);
  }

}
