import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {map, Observable, startWith, Subscription} from "rxjs";
import {SelectionChange, SelectionModel} from "@angular/cdk/collections";
import {CheckboxItem, CheckboxListSelectionChanged, CheckListSelectionEvent} from "@shared/ag-checkbox-list/ag-checkbox-list.component.ds";
import {AgCheckboxListService} from "@shared/ag-checkbox-list/services/ag-checkbox-list.service";
import {SelectionChangeType} from "@data/enums/data.enums";

@Component({
  selector: 'ag-checkbox-list',
  templateUrl: './ag-checkbox-list.component.html',
  styleUrls: ['./ag-checkbox-list.component.scss']
})
export class AgCheckboxListComponent implements OnInit {
  @ViewChild('checkboxContainer', {static: false}) checkboxContainer!: ElementRef;
  @Input() title: string = "Default Title";
  @Output() selectionChanged = new EventEmitter<CheckboxListSelectionChanged>();
  @Output() refreshSelectionList = new EventEmitter
  checkboxItems: CheckboxItem[] = [];
  searchFormControl: FormControl<any> = new FormControl<any>('');
  filteredCheckboxItems$: Observable<CheckboxItem[]>;
  checkboxSelection = new SelectionModel<CheckboxItem>(true);
  disableCheckListSelectionChanged: boolean = false;
  checkboxListServiceDataChange!: Subscription;
  checkboxListServiceSetSelection!: Subscription;
  checkboxSelectionChanged!: Subscription;

  constructor(private _checkboxListService: AgCheckboxListService) {
    this.initSubscriptions();
    this.filteredCheckboxItems$ = this.searchFormControl.valueChanges.pipe(startWith(''), map(value => this.filterSearchOptions(value || '')));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.checkboxListServiceDataChange.unsubscribe();
    this.checkboxListServiceSetSelection.unsubscribe();
    this.checkboxSelectionChanged.unsubscribe();
  }

  hasItems() {
    return this.checkboxItems && this.checkboxItems.length > 0;
  }

  // Method to handle Select All toggle
  toggleSelectAll(): void {
    if (this.allSelected()) {
      // clear triggers the event only once
      this.checkboxSelection.clear();
    } else {
      const selectionList: CheckboxItem[] = [];
      // disable the even so we can select each one accordingly
      this.disableCheckListSelectionChanged = true;
      this.checkboxItems.forEach(item => {
        if (!this.checkboxSelection.isSelected(item)) {
          selectionList.push(item);
          this.checkboxSelection.select(item);
        }
      });
      this.disableCheckListSelectionChanged = false;
      // Fire the event once after all of them have been selected
      this.handleSelectionChange(selectionList, SelectionChangeType.ADD);
    }
  }

  partiallySelected() {
    const result = this.checkboxItems.some(item => this.checkboxSelection.isSelected(item));
    return result && !this.allSelected();
  }

  allSelected() {
    const result = this.checkboxItems.every(item => this.checkboxSelection.isSelected(item));
    return result;
  }

  autoCompleteDisplayValue(childItem: CheckboxItem | string): string {
    // If the selected value is an object, display the 'item' property
    if (typeof childItem === 'string') {
      return childItem;
    }
    return childItem ? childItem.label : '';
  }

  selectedItemToggle(item: CheckboxItem) {
    this.checkboxSelection.toggle(item);
  }

  getNodeById(id: string) {
    const matchedNode = this.checkboxItems.find(item => item.id === id);
    if (matchedNode === undefined) {
      console.warn(`getNodeById - Could not find a matching item with id: ${id}`);
      return null;
    }
    return matchedNode;
  }

  hasValue() {
    return !!this.searchFormControl.getRawValue()
  }

  clearValue(event: Event) {
    this.searchFormControl.setValue('');
  }

  isSelected(checkboxItem: CheckboxItem) {
    return this.checkboxSelection.hasValue() && this.checkboxSelection.isSelected(checkboxItem)
  }

  onAutoCompleteMatOption(event: MouseEvent, checkboxItem: CheckboxItem) {
    // this method, with the mat-option-span class allows the dropdown to stay open when there is text in the autocompleted input
    event.stopPropagation();
    this.handleOnClickChange(checkboxItem);
  }

  onAutoCompleteSelectionChanged(checkboxItem: CheckboxItem) {
    this.handleOnClickChange(checkboxItem);
    if (checkboxItem.id) {
      this.scrollToNode(checkboxItem.id);
    }
  }

  onAutoCompleteCheckboxClicked(event: MouseEvent) {
    // When there is no text in the the matInput then this method is called.
    // This means that we need to stopPropagation here in order for the dropdown window stays open
    event.stopPropagation();
  }

  scrollToNode(nodeId: string) {
    // Find the DOM element for the node by ID.  This is the ID generated by the service.
    const nodeElement = document.getElementById(nodeId);
    if (nodeElement && this.checkboxContainer) {
      const container = this.checkboxContainer.nativeElement;
      const offsetTop = nodeElement.offsetTop;
      // Scroll the container to the node's position
      container.scrollTop = offsetTop - container.offsetTop;
    }
  }

  private initSubscriptions() {
    this.checkboxListServiceDataChange = this._checkboxListService.dataChange.subscribe(data => this.handleCheckboxListServiceDataChange(data));
    this.checkboxListServiceSetSelection = this._checkboxListService.setSelection.subscribe(event => this.handleCheckboxListServiceSetSelection(event));
    this.checkboxSelectionChanged = this.checkboxSelection.changed.subscribe(change => this.handleCheckboxSelectionChange(change));
  }

  private getAllCurrentlySelectedItems() {
    return this.checkboxSelection.selected;
  }

  private getUpdatedNodesToSelect(currentlySelectedItems: CheckboxItem[]) {
    return this.checkboxItems.filter(item => currentlySelectedItems
      .some(selectedItem => item.label === selectedItem.label));
  }

  private clearListData() {
    this.checkboxSelection.clear(false); // clear to ensure we don't trigger the changed subscription.
  }

  private setDataSource(data: CheckboxItem[]) {
    this.checkboxItems = data;
  }

  private setNodesToSelect(nodesToReselect: CheckboxItem[]) {
    // temporarily disable the selectChange event from firing when we re-set the selection.
    this.disableCheckListSelectionChanged = true;
    this.checkboxSelection.setSelection(...nodesToReselect);
    this.disableCheckListSelectionChanged = false;
  }

  private triggerSearchFromControlValueChangeEvent(data: CheckboxItem[]) {
    // Manually trigger valueChanges by setting the current value to itself -- This will ensure the
    // search form control has the latest data source.
    // if data.length is 0, it means everything is unselected, to reset the control.
    this.searchFormControl.setValue(data.length === 0 ? '' : this.searchFormControl.value || '', {emitEvent: true});
  }

  private handleCheckboxListServiceDataChange(data: CheckboxItem[]) {
    // new data is coming in, so we should reset our current lists
    const currentlySelectedItems = this.getAllCurrentlySelectedItems();
    this.clearListData();
    this.setDataSource(data);
    const nodesToReselect: CheckboxItem[] = this.getUpdatedNodesToSelect(currentlySelectedItems);
    this.setNodesToSelect(nodesToReselect);
    this.triggerSearchFromControlValueChangeEvent(data);
    this.handleSelectionChange(nodesToReselect, SelectionChangeType.REFRESH);
  }

  private selectCheckboxItemById(id: string) {
    const selectedNode = this.getNodeById(id);
    if (selectedNode) {
      this.selectedItemToggle(selectedNode);
    }
    return selectedNode;
  }

  private filterSearchOptions(value: string | CheckboxItem | null): CheckboxItem[] {
    if (typeof value === 'string') {
      let filteredItems: CheckboxItem[];
      filteredItems = this.checkboxItems.filter(item => item.label?.toLowerCase().includes(value?.toLowerCase()));
      return filteredItems;
    } else if (value === null) {
      console.warn("CheckboxItem value is null");
    } else {
      const selectedNode = this.selectCheckboxItemById(value.id);
      if (selectedNode) {
        return this.filterSearchOptions(selectedNode.label);
      }
    }
    return this.checkboxItems;
  }

  private handleCheckboxSelectionChange(change: SelectionChange<CheckboxItem>) {
    if (!this.disableCheckListSelectionChanged) {
      if (change.added.length > 0) {
        this.handleSelectionChange(change.added, SelectionChangeType.ADD);
      } else if (change.removed.length > 0) {
        this.handleSelectionChange(change.removed, SelectionChangeType.DELETE);
      } else {
        console.warn("ag-checkbox-tree - Invalid selection type");
      }
    }
  }

  private handleSelectionChange(nodes: CheckboxItem[], type: SelectionChangeType) {
    this.selectionChanged.emit({
      items: nodes,
      changeType: type
    });
  }

  private handleOnClickChange(selectedCheckboxItem: CheckboxItem) {
    this.selectCheckboxItemById(selectedCheckboxItem.id);
  }

  private handleCheckboxListServiceSetSelection(event: CheckListSelectionEvent) {
    if (event && event.itemsToSelect && event.itemsToSelect.length > 0) {
      let checkboxItemsToSelect: CheckboxItem[] = []
      this.disableCheckListSelectionChanged = true;
      event.itemsToSelect.forEach(item => {
        if (!this.checkboxSelection.isSelected(item)) {
          this.checkboxSelection.toggle(item);
          checkboxItemsToSelect.push(item);
        }
      });
      this.disableCheckListSelectionChanged = false;
      if (event.triggerSelectChangeEvent) {
        this.handleSelectionChange(checkboxItemsToSelect, SelectionChangeType.ADD);
      }
    }
  }
}
