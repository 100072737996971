import {GridColumn, GridColumnGroupDef} from "@data/interefaces/data.interfaces";
import {TableColumnsUtil} from "@data/util/table-columns-util";
import {FeatureComponentMode} from "@core/enums/core.enums";

export class VerbatimFileRowsComponentUtil extends TableColumnsUtil {

  protected static override setColumn(column: GridColumn, featureComponentMode: FeatureComponentMode) {
    switch (column.field) {
      case 'surveyCode':
      case 'verbatimFileName':
      case 'verbatimFileUploadDate':
        // These columns are the same for every row, and are no longer a Data Filter.
        // Therefore, hide them by default and turn off the filtering.
        if (FeatureComponentMode.DEFAULT !== featureComponentMode) {
          column.hide = true;
          column.filter = null;
          column.filterParams = null;
        }
        break;
      default:
        // leave as is
        break;
    }
  }

}
