import {TransferData} from "@data/interefaces/data.interfaces";

export enum EventType {
  NOTIFICATION, PROGRESS, SIDE_NAVIGATION
}

export interface ApplicationEvent {
  type: EventType;
  data: any;
}

export interface UserCache {
  principleName: string;
  pages: PagesCache[];
}

export interface PagesCache {
  pageName: string;
  columns: ColumnCache[];
}

export interface ColumnCache {
  columnDef: string;
  hide: boolean;
}

export interface Feature extends TransferData {
  featureType: string,
  featureFlags: FeatureFlag[]
}

export interface FeatureFlag {
  featureFlag: string,
  enabled: boolean
}

export interface FeatureConfig {
  feature: string,
  featureFlag: string
}
