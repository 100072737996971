import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = [];

  constructor(private _router: Router, private _location: Location) {
  }

  push(url: string) {
    this.history.push(url);
  }

  back(parentPath: string, pathsToIgnore?: string[]): void {
    this.history.pop();
    if (this.history.length > 0) {
      if (pathsToIgnore) {
        this.goBackIgnoringPaths(parentPath, pathsToIgnore);
      } else {
        this._location.back();
      }
    } else {
      this.navigateToParentPath(parentPath);
    }
  }

  private goBackIgnoringPaths(parentPath: string, pathsToIgnore: string[]): void {
    const reversedHistory = [...this.history].reverse();
    const targetHistoryEntry = reversedHistory.find(entry => !pathsToIgnore.some(path => entry.includes(path) || entry == "error"));

    if (targetHistoryEntry) {
      this._router.navigateByUrl(targetHistoryEntry).catch(error => this._router.navigate(["/error"]));
    } else {
      this.navigateToParentPath(parentPath);
    }
  }

  private navigateToParentPath(parentPath: string) {
    this._router.navigateByUrl(parentPath).catch(error => this._router.navigate(["/error"]));
  }

}
