export enum TransferStatus {
  NOT_SCHEDULED = "not scheduled",
  SCHEDULED = "scheduled",
  RUNNING = "running",
  IN_PROGRESS = "in progress",
  NOT_RUNNING = "not running",
  PAUSED = "paused",
  STOPPED = "stopped"
}

export enum TransferRunStatus {
  IN_PROGRESS = "in progress",
  ABORTED = "aborted",
  COMPLETED = "completed",
  COMPLETED_WITH_ERRORS = "completed with errors",
  STOPPED = 'stopped'
}

export enum TaskStatus {
  PENDING = "pending",
  IN_PROGRESS = "in progress",
  COMPLETED_SUCCESSFULLY = "completed successfully",
  COMPLETED_WITH_ERRORS = "completed with errors",
  TIMED_OUT = "timed out",
  CANCELLED = "cancelled"
}

export enum ColumnFilterType {
  TEXT = "agTextColumnFilter",
  NUMBER = "agNumberColumnFilter",
  DATE = "agDateColumnFilter",
  DROPDOWN = "agSetColumnFilter",
  MULTI = "agMultiColumnFilter"
}

export enum AgLogLevel {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error"
}

export enum VerbatimStatus {
  SUCCESS = "Success",
  ERROR = "Error"
}

export enum EnvironmentConfiguration {
  APPLICATION_ENVIRONMENT_NAME = "application-environment-name"
}

export enum ResponseStatus {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  ERROR = "Error",
  COMPLETE = "Complete",
  SCREENED = "Screened"
}

/**
 * enum based on - core.response_type_enum
 */
export enum ResponseType {
  INVALID = "Invalid",
  ACTIVE = "Active",
  MERGED = "Merged",
  MERGE_RESULT = "Merge Result"
}

export enum LinkType {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

export enum FileRequestStatus {
  NONE = 'None',
  REQUESTED = 'Requested',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
  COMPLETED_WITH_ERRORS = 'Completed with Errors'
}

export enum SurveySubject {
  SUPPLIER = "Supplier",
  RETAILER = "Retailer"
}

export enum NotificationType {
  ERROR = "Error",
  INFO = "Info",
  WARNING = "Warning",
  SUCCESS = "Success"
}

export enum SurveyFileUploadStatus {
  PENDING = "pending",
  IN_PROGRESS = "in progress",
  COMPLETED_SUCCESSFULLY = "completed successfully",
  COMPLETED_WITH_DATA_ERRORS = "completed with data errors",
  COMPLETED_WITH_PROCESSING_ERRORS = "completed with processing errors"
}

export enum TimeUnit {
  SECOND = "Second",
  MINUTE = "Minute",
  HOUR = "Hour",
  DAY = "Day",
  WEEK = "Week"
}

export enum ApplicationStatus {
  ONLINE = "Online",
  OFFLINE = "Offline",
}

export enum EventTypeStatusLevel {
  OK = "OK",
  WARNING = "Warning",
  OFFLINE = "Offline",
}

export enum EventTypeStatus {
  IDLE = "Idle",
  WORKING = "Working",
  STANDBY = "Standby",
  BUSY = "Busy",
}

export enum EventProcessorStatus {
  STARTING = "Starting",
  STARTED = "Started",
  STOPPING = "Stopping",
  STOPPED = "Stopped",
  PAUSED = "Paused",
  IDLE = "Idle",
  BUSY = "Busy",
}

export enum EventAction {
  CANCEL = "cancel",
  ABORT = "abort",
}

export enum YesNoValue {
  YES_VALUE = "Y",

  // Strictly speaking, NO_VALUE should be an empty string `""`.
  // BUT: Must be a space `" "` b/c components handle empty strings inconsistently which leads to weird behaviour.
  //      Luckily, the back end and/or the database will handle trimming values, so it makes no functional difference.
  //      This is a front-end-only weirdness that is causing confusion and delay during development; we will fix it eventually.
  NO_VALUE = " "
}

export enum YesNoUndeterminedValue {
  UNDETERMINED_VALUE = "",
  YES_VALUE = YesNoValue.YES_VALUE,
  // In additional to the above comment re: empty string, there is a subtlety with Bulk Operations.
  // Currently, an empty string for a bulk operation means "do not change current value".
  // This is different from "clear current value".
  // So once again, we need to use a space to make that distinction.
  NO_VALUE = YesNoValue.NO_VALUE
}

export enum SelectionChangeType {
  ADD = 'add',
  DELETE = 'delete',
  REFRESH = 'refresh'
}

export enum ProcessingStatus {
  NONE = "",
  PROGRESS = "in progress",
  COMPLETE_SUCCESS = "completed successfully",
  SYSTEM_ERROR = "failed with system error",
  INFRASTRUCTURE_ERROR = "failed with infrastructure error",
  DATA_ERROR = "failed with validation error"
}

export enum DownloadRequestProcessStatus {
  PENDING = 'Pending',
  IN_PROGRESS = 'In Progress',
  READY_FOR_DOWNLOAD = 'Ready for Download',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  ABORTED = 'Aborted',
  AVAILABLE = 'Available'
}
