<section class="pd-l-s pd-r-s task" id="task">
  <div class="flex-row flex-g-s go-back-block">
    <a (click)="goBack()" *ngIf="goBackTitle" color="primary" mat-button>
      <i class="fa-sharp fa-regular fa-arrow-left fa-lg mg-r-s"></i>
      {{ goBackTitle }}
    </a>
    <ag-subtitle [subTitleSegments]="subtitleSegments"></ag-subtitle>
  </div>

  <section class="sidebar-and-main">
    <aside>
      <div *ngFor="let item of this.filteredSideBarItems">
        <button (click)="taskSidebarItemClicked(item)"
                *ngIf="!item.urlSupport && !isUrlMode || item.urlSupport && isUrlMode"
                [disabled]="item.disabled"
                [ngClass]="{'primary-alt-button': item.selected, disabled: item.disabled}"
                class="sidebar-item flex-column flex-centered primary-font-color">
          <i [ngClass]="{'primary-color': !item.selected}" class="fa-sharp fa-light {{item.icon}} mg-b-s"></i>
          <span>{{ item.title }}</span>
        </button>
      </div>

    </aside>
    <main class="mg-l-s">
      <template [ngTemplateOutlet]="currentTemplateOutlet"></template>
    </main>
  </section>
</section>
