import {Component, Input} from '@angular/core';
import {OpenLinkInvitee, ViewMailoutFieldsets, ViewMailoutTemplates} from "src/app/data/interefaces/panel.interfaces";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ViewSurveyBuCategories, ViewSurveyLocales, ViewSurveyRespondentCompanies, ViewSurveyRespondentDivisions,} from "src/app/data/interefaces/survey.spec.interfaces";
import {OpenLinkInviteesService} from "src/app/data/panel/open.link.invitees.service";
import {PanelService} from "src/app/data/panel/panel.service";
import {SurveySpecService} from "src/app/data/survey-spec/survey.spec.service";
import {openLinkDisplayTableColumns, OpenLinkInviteeWithProcessStatus} from "@pages/panel/open-link-invitees/open-link-invitees-bulk/open-link-invitees-bulk.component.ds";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {BatchProcessResult} from "@data/interefaces/data.interfaces";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-open-link-invitees-bulk',
  templateUrl: './open-link-invitees-bulk.component.html',
  styleUrls: ['./open-link-invitees-bulk.component.scss']
})
export class OpenLinkInviteesBulkComponent extends BulkBaseComponent {
  @Input() override selectedRows: OpenLinkInvitee[] = [];
  mailoutTemplateCodeList: ViewMailoutTemplates[] | undefined = [];
  mailoutFieldsetCodeList: ViewMailoutFieldsets[] | undefined = [];
  invitationCompanyCodeList: ViewSurveyRespondentCompanies[] | undefined = [];
  invitationDivisionCodesList: ViewSurveyRespondentDivisions[] | undefined = [];
  buCategoriesList: ViewSurveyBuCategories[] | undefined = [];
  surveyLanguageList: ViewSurveyLocales[] | undefined = [];
  protected override formGroup: FormGroup<{ [K in keyof OpenLinkInvitee]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = openLinkDisplayTableColumns;

  constructor(private _openLinkInviteesService: OpenLinkInviteesService, private _panelService: PanelService, private _surveySpecService: SurveySpecService) {
    super();
  }

  override ngOnInit() {
    this.displayProgressBar(true);
    super.ngOnInit();
    this.displayProgressBar(false);
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<OpenLinkInviteeWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof OpenLinkInvitee)) {
    return this.getControl<OpenLinkInvitee>(controlName);
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._openLinkInviteesService.getLatestOpenLinkInviteesBatchProcess();
  }

  protected override initFormGroup() {
    let isBulkMailoutMode = this.featureComponentMode == FeatureComponentMode.BULK_MAILOUT;
    this.formGroup = new FormGroup<{ [K in keyof OpenLinkInvitee]?: FormControl<any> }>(
      {
        invalid: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        invitationCompanyCode: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        invitationDivisionCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        surveyLanguage: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        nominatedByCodes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        nominationNotes: new FormControl({
          value: null,
          disabled: isBulkMailoutMode
        }, []),
        mailoutTemplateCode: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }, Validators.required),
        mailoutFieldSetCode: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }, Validators.required),
        mailoutDatetime: new FormControl({
          value: null,
          disabled: !isBulkMailoutMode
        }),
      });
  }

  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_MAILOUT:
        this.title = "Open Link Invitation Bulk Mailout";
        this.subtitle = "Open Link Invitation Bulk Mailout Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkMailoutDropdowns();
        break;
      case FeatureComponentMode.BULK_EDIT:
        this.title = "Open Link Invitation Bulk Edit";
        this.subtitle = "Open Link Invitation Bulk Edit Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkUpdateDropdowns();
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Open Link Invitation Bulk Upload";
        this.subtitle = "Open Link Invitation Bulk Upload Configuration";
        this.submitButtonText = "Start Upload";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  protected override startBatchProcess(selectedRows: OpenLinkInvitee[]): Promise<any> {
    return this._openLinkInviteesService.startOpenLinkInviteesBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]) {
    let returnList: T[] = [];
    rowData.map(item => {
      const element = item as OpenLinkInviteeWithProcessStatus;
      if (!element) {
        throw new Error('Element is not the proper type.');
      }
      if ((element.responseCode && !element.openLinkCode) || (!element.responseCode && element.openLinkCode)) {
        element.processingNote = 'To add, response code and open link code both should be null, for update, both should be provided';
        returnList.push(element as T);
      }
    });

    return returnList;
  }

  private loadBulkMailoutDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._panelService.getMailoutTemplates(surveyCode).then(result => {
      this.mailoutTemplateCodeList = result;
    });
    this._panelService.getMailoutFieldsets(surveyCode).then(result => {
      this.mailoutFieldsetCodeList = result;
    });
  }

  private loadBulkUpdateDropdowns() {
    let surveyCode = this.selectedRows[0].surveyCode;
    this._surveySpecService.getSurveyRespondentCompanies(surveyCode).then(result => {
      this.invitationCompanyCodeList = result;
    });
    this._surveySpecService.getSurveyRespondentDivisions(surveyCode).then(result => {
      this.invitationDivisionCodesList = result;
    });
    this._surveySpecService.getSurveyLocales(surveyCode).then(result => {
      this.surveyLanguageList = result;
    });
    this._surveySpecService.getSurveyBuCategories(surveyCode).then(result => {
      this.buCategoriesList = result;
    });
  }
}
