import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {ActivatedRoute} from "@angular/router";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-response-file-task',
  templateUrl: './response-file-task.component.html',
  styleUrls: ['./response-file-task.component.scss']
})
export class ResponseFileTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Detail",
      icon: "fa-file",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.DETAIL
    },
    {
      title: "Upload",
      icon: "fa-upload",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    }
  ];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/data-management/survey/response-file";

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }

  protected readonly FeatureComponentMode = FeatureComponentMode;
}
