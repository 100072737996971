import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseComponent} from "src/app/pages/base.component";
import {TaskSidebarItem} from "src/app/shared/ag-task/ag-task.component.ds";
import {OpenLinkInvitee} from "src/app/data/interefaces/panel.interfaces";
import {ActivatedRoute} from "@angular/router";
import {openLinkDisplayTableColumns} from "@pages/panel/open-link-invitees/open-link-invitees-bulk/open-link-invitees-bulk.component.ds";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-open-link-invitees-task',
  templateUrl: './open-link-invitees-task.component.html',
  styleUrls: ['./open-link-invitees-task.component.scss']
})
export class OpenLinkInviteesTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Add Row",
      icon: "fa-plus",
      selected: true,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.ADD_ROW
    },
    {
      title: "Edit Row",
      icon: "fa-pen-to-square",
      selected: false,
      urlSupport: true,
      disabled: true,
      mode: FeatureComponentMode.EDIT_ROW
    },
    {
      title: "Bulk Mailout",
      icon: "fa-envelopes-bulk",
      selected: false,
      urlSupport: false,
      disabled: false,
      mode: FeatureComponentMode.BULK_MAILOUT
    },
    {
      title: "Bulk Edit",
      icon: "fa-pen",
      selected: false,
      urlSupport: false,
      disabled: false,
      mode: FeatureComponentMode.BULK_EDIT
    },
    {
      title: "Upload",
      icon: "fa-upload",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    }
  ];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: OpenLinkInvitee[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/forms/survey-respondents/open-link-invitees";
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = openLinkDisplayTableColumns;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }
}
