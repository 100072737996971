import {Injectable} from "@angular/core";
import {ApiService} from "@core/api/api.service";
import {FollowChangePath} from "@data/interefaces/itf_system.interfaces";

@Injectable({
  providedIn: 'root'
})
export class FollowChangePathService {
  constructor(private _apiService: ApiService) {
  }

  public getFollowChangePath(): Promise<FollowChangePath[]> {
    return this._apiService.makeGetServiceCall<FollowChangePath[]>(`/v2/api/itf-system/follow-change-path`);
  }
}
