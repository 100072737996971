import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TaskSidebarItem} from "@shared/ag-task/ag-task.component.ds";
import {tableColumns} from "@pages/product-spec/product-sponsor-verbatim/product-sponsor-verbatim.component.ds";
import {BaseComponent} from "@pages/base.component";
import {ProductSponsorVerbatim} from "@data/interefaces/product.sponsor.verbatim.interfaces";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-product-sponsor-verbatim-task',
  templateUrl: './product-sponsor-verbatim-task.component.html',
  styleUrls: ['./product-sponsor-verbatim-task.component.scss']
})
export class ProductSponsorVerbatimTaskComponent extends BaseComponent implements AfterViewInit {
  featureComponentSidebarEl: TaskSidebarItem[] = [
    {
      title: "Bulk Include/Exclude",
      icon: "fa-arrow-right-to-arc",
      selected: false,
      urlSupport: false,
      disabled: true,
      mode: FeatureComponentMode.BULK_INCLUDE
    },
    {
      title: "Upload",
      icon: "fa-upload",
      selected: false,
      urlSupport: true,
      disabled: false,
      mode: FeatureComponentMode.UPLOAD
    }
  ];
  @Input() selectedFeatureMode: FeatureComponentMode | undefined;
  @Input() selectedRows!: ProductSponsorVerbatim[];
  @Input() preSelectedFeatureMode!: FeatureComponentMode;
  @Output() goBackEvent: EventEmitter<any> = new EventEmitter<any>();
  isUrlMode: boolean = false;
  urlModeGoBackUrl: string = "/forms/survey-respondents/unique-link-invitees";
  protected readonly FeatureComponentMode = FeatureComponentMode;
  protected readonly tableColumns = tableColumns;

  constructor(protected _activatedRoute: ActivatedRoute) {
    super();
    let paramMode = this._activatedRoute.snapshot.params['mode'];
    if (paramMode) {
      this.isUrlMode = true;
      this.selectedFeatureMode = paramMode;
    }
  }

  ngAfterViewInit(): void {
  }
}
