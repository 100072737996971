import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {FileRequestStatus} from "@data/enums/data.enums";
import {
  BatchProcessResult,
  CompanyWeightSet,
  DataItem,
  ProductPeriod,
  RespondentGroupPeriod
} from "@data/interefaces/data.interfaces";
import {ProductSpecService} from "@data/product-spec/product.spec.service";
import {productPeriodDisplayTableColumns, ProductPeriodWithProcessStatus} from "@pages/product-spec/product-period/product-period-bulk/product-period-bulk.component.ds";
import {RespondentGroupPeriodService} from "@data/product-spec/respondent.group.period.service";
import {CompanyWeightSetService} from "@data/product-spec/company.weight.set.service";
import {LovService} from "@data/common/lov.service";
import {BulkBaseComponent} from "@pages/bulk.base.component";
import {Util} from "@data/util/util";
import {FeatureComponentMode} from "@core/enums/core.enums";

@Component({
  selector: 'app-product-period-bulk',
  templateUrl: './product-period-bulk.component.html',
  styleUrls: ['./product-period-bulk.component.scss']
})
export class ProductPeriodBulkComponent extends BulkBaseComponent {
  @Input() override selectedRows: ProductPeriod[] = [];
  respondentGroupPeriodList: RespondentGroupPeriod[] | undefined;
  companyWeightSet: CompanyWeightSet[] | undefined;
  approvalStatusList: DataItem[] | undefined;
  maskingOverrideList: DataItem[] | undefined;
  languagesList: string[] | undefined;


  protected override formGroup: FormGroup<{ [K in keyof ProductPeriod]?: FormControl<any> }> = new FormGroup<any>([]);
  protected override readonly tableColumns = productPeriodDisplayTableColumns;

  constructor(private _productSpecService: ProductSpecService, private _resGroupPeriodService: RespondentGroupPeriodService,
              private _companyWeightSetService: CompanyWeightSetService, private _lovService: LovService) {
    super();
  }

  override ngOnInit() {
    this.displayProgressBar(true);
    super.ngOnInit();
    this.displayProgressBar(false);
  }

  uploadFinished(event: { status: number; message: string; file?: File }) {
    this.processUploadedFile<ProductPeriodWithProcessStatus>(event);
  }

  getFormControl(controlName: (keyof ProductPeriod)) {
    return this.getControl<ProductPeriod>(controlName);
  }

  protected override getBatchProcessResultByType(): Promise<BatchProcessResult> {
    return this._productSpecService.getLatestBatchProcess();
  }

  protected override startBatchProcess(selectedRows: ProductPeriod[]): Promise<any> {
    return this._productSpecService.startBatchProcess(selectedRows, this.featureComponentMode.toString());
  }

  protected override validateRowData<T>(rowData: T[]): T[] {
    return [];
  }

  protected override initFormGroup() {
    let isBulkEditMode = this.featureComponentMode == FeatureComponentMode.BULK_EDIT;
    this.formGroup = new FormGroup<{ [K in keyof ProductPeriod]?: FormControl<any> }>(
      {
        include: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        respondentGroupCode: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        languages: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        allRespondentCompanies: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        standardRespondentWeightSetCode: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        nominatedBy: new FormControl({
          value: null,
          disabled: !isBulkEditMode,
        }),
        maskingOverride: new FormControl({
          value: null,
          disabled: !isBulkEditMode
        }),
        approvalStatus: new FormControl({
          value: null,
          disabled: !isBulkEditMode
        }),
        productSummaryFileStatus: new FormControl({
          value: null,
          disabled: !isBulkEditMode
        }),
      });
  }

  protected override initFieldsByFeatureComponentMode() {
    switch (this.featureComponentMode) {
      case FeatureComponentMode.BULK_EDIT:
        this.title = "Product Period Bulk Edit";
        this.subtitle = "Product Period Bulk Edit Configuration";
        this.submitButtonText = "Save";
        this.updateStage = 2;
        this.loadBulkUpdateDropdowns();
        break;
      case FeatureComponentMode.UPLOAD:
        this.title = "Product Period Bulk Upload";
        this.subtitle = "Product Period Bulk Upload Configuration";
        this.submitButtonText = "Upload and Process";
        this.updateStage = 1;
        break;
      default:
        break;
    }
  }

  private loadBulkUpdateDropdowns() {
    let period = this.selectedRows[0].period;
    let marketCode = this.selectedRows[0].marketCode;
    let industryCode = this.selectedRows[0].industryCode;
    let surveySubject = this.selectedRows[0].surveySubject;
    let productPeriodId = this.selectedRows[0].productPeriodId;
    this._resGroupPeriodService.getRespondentGroup(period, marketCode, industryCode, surveySubject).then(result => {
      this.respondentGroupPeriodList = result;
    });
    this._companyWeightSetService.getCompanyWeightSet(period, marketCode, industryCode, surveySubject).then(result => {
      this.companyWeightSet = result;
    });
    this._lovService.getApprovalStatusList().then(result => {
      this.approvalStatusList = result.listElements;
    });
    this._lovService.getMaskingOverrideList().then(result => {
      this.maskingOverrideList = result.listElements;
    });
    this._productSpecService.getProductReportLabel(productPeriodId, period, marketCode, industryCode, surveySubject).then(result => {
      this.languagesList = result.map<string>(element => {
        return element.language;
      });
    });
  }

}

