import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {BaseComponent} from "@pages/base.component";
import {ActivatedRoute, UrlSegment} from "@angular/router";
import {TransferRunService} from "@data/transfer-run/transfer.run.service";
import {Util} from "@data/util/util";
import {ExtendedTransferRun} from "@data/interefaces/extended.data.interfaces";
import {AgConfirmationDialogComponent} from "@shared/ag-confirmation-dialog/ag-confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {TransferRunStatus, TransferStatus} from "@data/enums/data.enums";
import {AgSidePanelComponent} from "@shared/ag-side-panel/ag-side-panel.component";
import {TransferConfigService} from "@data/transfer-config/transfer.config.service";
import {FieldConfiguration} from "@data/interefaces/data.interfaces";
import {Location} from '@angular/common'

@Component({
  selector: 'app-transfer-run-detail',
  templateUrl: './transfer-run-detail.component.html',
  styleUrls: ['./transfer-run-detail.component.scss']
})
export class TransferRunDetailComponent extends BaseComponent implements AfterViewInit {

  readonly util = Util;
  transferId: string | undefined;
  selectedTabId: number = 0;
  auxLinks: any;
  transferRun: ExtendedTransferRun | undefined;
  @ViewChild("runParametersSidePanel") runParametersSidePanel: AgSidePanelComponent | null = null;
  runParameters: string | undefined;
  showSkeleton: boolean = true;
  protected readonly transferStatus = TransferStatus;

  constructor(private _activatedRoute: ActivatedRoute, private _transferConfigService: TransferConfigService, private _transferRunService: TransferRunService, public _dialog: MatDialog, private _location: Location) {
    super();
    this.auxLinks = new Map([['/home/welcome', 'Home']]);
  }

  override ngOnInit() {
    super.ngOnInit();
    this.transferId = this._activatedRoute.snapshot.params['referenceCode'];
    this.selectedTabId = this.getRunDetailTabNameFromUrl();
    this.displayRequestedTab(this.selectedTabId);
  }

  ngAfterViewInit(): void {
    this.refreshView();
  }

  getRunDetailTabNameFromUrl() {
    let urlSegments: UrlSegment[] = this._activatedRoute.snapshot.url
    const lastUrlSegment = urlSegments[urlSegments.length - 1];
    return lastUrlSegment && lastUrlSegment.toString() === 'messages' ? 1 : 0
  }

  displayRequestedTab(tabIndex: number) {
    this.selectedTabId = tabIndex;
    this.displaySelectedTab(tabIndex);
  }

  isA3Transfer(transferCode: string | undefined) {
    return transferCode !== undefined && transferCode !== null && transferCode.toLowerCase().startsWith("a3");
  }

  onStopTransferClick(transferRun: ExtendedTransferRun | undefined) {
    if (transferRun !== undefined) {
      const dialogRef = this._dialog.open(AgConfirmationDialogComponent, {
        data: {
          title: "Confirm", message: "Are you sure you want to stop" +
            " the transfer '" + transferRun.transferId + "' ?"
        }
      })
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.displayProgressBar(true);
          this._transferRunService.stopTransfer(transferRun.transferId).then(result => {
            if (result.status) {
              // immediately set status to stop to not allow accidental double clicks.
              transferRun.transferState = TransferRunStatus.STOPPED;
              this.displayNotificationMessage('success', `Transfer ${transferRun.transferId} ${TransferRunStatus.STOPPED}.`);
              // let refresh get proper status.
              this.refreshView();
            }
            this.displayProgressBar(false);
          }).catch(error => {
              this.displayNotificationMessage('error', JSON.stringify(error));
              this.displayProgressBar(false);
            }
          );
        }
      })
    }
  }

  onDisplayRunConfigurationClick(transferTypeCode: string | undefined, runConfiguration: string | undefined) {
    this.runParametersSidePanel?.toggle();
    this._transferConfigService.getTransferConfiguration(transferTypeCode!).then(result => {
      this.runParameters = this.mergeResult(runConfiguration!, result.defaultRunConfiguration)
      this.showSkeleton = false;
    });
  }

  async refreshView() {
    await this._transferRunService.getTransfer(this.transferId!).then(result => {
      this.transferRun = result as ExtendedTransferRun;
      if (!this.isA3Transfer(result?.transferTypeCode)) {
        this.displayRequestedTab(1);
      }
    });
  }

  private displaySelectedTab(selectedTabId: number) {
    if (selectedTabId == 0) {
      this._location.replaceState(`/system/transfers/run-history/${this.transferId}/tasks`);
    } else if (selectedTabId == 1) {
      this._location.replaceState(`/system/transfers/run-history/${this.transferId}/messages`);
    }
  }

  private mergeResult(runParameters: string, defaultRunParameters: string): string {
    let fieldConfigList: FieldConfiguration[] = JSON.parse(defaultRunParameters);
    let runParameterObj = JSON.parse(runParameters)
    fieldConfigList.forEach(config => {
      Object.keys(runParameterObj).forEach(key => {
        if (config.fieldId == key) {
          config.value = runParameterObj[key];
        }
      });
    });

    return JSON.stringify(fieldConfigList);
  }
}
